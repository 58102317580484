// axiosInstance.js
import axios from "axios";

const axiosInstance = axios.create({
  baseURL: "https://backend-vab7.onrender.com/",
});

export default axiosInstance;

export const weburl = "https://backend-vab7.onrender.com/";
export const adminurl = "https://admin.hospitaltraders.com/";

// export const weburl = 'https://backend-vab7.onrender.com/';
