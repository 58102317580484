import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useBlogContext } from "../../fetchdata/BlogContext";
import axios from "axios";

const Footer = () => {
  const { Headers, isHeader, cartItems, AllProducts, AllCategoriess } =
    useBlogContext();

  return (
    <>
      {/* Footer */}
      <footer className="footer  bg-light">
        <div className="footer-top py-4 py-lg-10 border-top">
          <div className="container">
            <div className="row g-4 border-bottom mb-4 pb-2 pt-4">
              {isHeader
                ? // Display loading skeletons while data is being fetched
                  Array.from({ length: 8 }).map((_, index) => (
                    <div className="col" key={index}>
                      <div
                        className="skeleton mt-1"
                        style={{
                          height: 22,
                          width: 100,
                          borderRadius: 5,
                        }}
                      ></div>
                    </div>
                  ))
                : Headers.footer !== undefined &&
                  Headers.footer.slice(3, 100).map((item, index) => (
                    <div className="m-0" key={index}>
                      <div className="widget widget-links">
                        <nav aria-label="breadcrumb">
                          <ol className="breadcrumb">
                            <li
                              className="breadcrumb-item active"
                              aria-current="page"
                            >
                              {" "}
                              <h4 className="widget-title mb-0 fw-medium h6">
                                {item.text}
                              </h4>{" "}
                            </li>
                            {item.children.map((childItem, childIndex) => (
                              <li
                                key={childIndex}
                                className="breadcrumb-item pe-2"
                                aria-current="page"
                              >
                                <Link
                                  className="widget-list-link "
                                  style={{ whiteSpace: "nowrap" }}
                                  to={childItem.link}
                                >
                                  {childItem.text}
                                </Link>
                              </li>
                            ))}
                          </ol>
                        </nav>
                      </div>
                    </div>
                  ))}
            </div>

            <div className="row g-4 g-lg-10">
              <div className="col-lg-8">
                <div className="row g-4">
                  {isHeader
                    ? Array.from({ length: 4 }).map((_, index) => (
                        <div className="col-md-3">
                          <div className="nav-item w-100 mb-4">
                            <div
                              className="skeleton mt-1"
                              style={{
                                height: 22,
                                width: 200,
                                borderRadius: 5,
                              }}
                            ></div>
                          </div>
                          {Array.from({ length: 5 }).map((_, index) => (
                            <div className="nav-item w-100 mb-2" key={index}>
                              <div
                                className="skeleton mt-1"
                                style={{
                                  height: 22,
                                  width: 100,
                                  borderRadius: 5,
                                }}
                              ></div>
                            </div>
                          ))}
                        </div>
                      ))
                    : Headers.footer !== undefined &&
                      Headers.footer.slice(0, 3).map((item, index) => (
                        <div className="col-12 col-md" key={index}>
                          <div className="widget widget-links">
                            <h4 className="widget-title fw-medium h6 text-secondary fw-bold">
                              {item.text}
                            </h4>
                            <ul className="widget-list">
                              {item.children.map((childItem, childIndex) => (
                                <li
                                  className="widget-list-item"
                                  key={childIndex}
                                >
                                  <Link
                                    className="widget-list-link "
                                    to={childItem.link}
                                  >
                                    {childItem.text}
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      ))}
                </div>
              </div>
              <div className="col-lg-4">
                <div className="mb-4">
                  <h4 className="mb-2 h4 fw-medium h6 text-secondary fw-bold">
                    CONTACT US
                  </h4>
                </div>
                <div className="mb-4">
                  <div className="row align-items-center">
                    <div className="col-auto">
                      <i
                        className="text-dark ri-customer-service-2-fill"
                        style={{ fontSize: "2.5rem" }}
                      />
                    </div>
                    {!isHeader && (
                      <div className="col">
                        <h6 className="mb-0 h6">24/7 Tech Support</h6>
                        <a
                          href={`tel:+91${Headers.phone}`}
                          className="nav-link-base h6 fw-light"
                        >
                          +91 {Headers.phone}
                        </a>
                      </div>
                    )}
                  </div>
                </div>
                <div className="mb-4">
                  <div className="row">
                    <div className="col-auto">
                      <i
                        className="text-dark ri-map-pin-2-fill"
                        style={{ fontSize: "2.5rem" }}
                      />
                    </div>
                    {!isHeader && (
                      <div className="col">
                        <h6 className="mb-1 font-weight-bold h6">
                          Address Info
                        </h6>
                        <span className="h6 fw-light"> {Headers.address}</span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="mb-4">
                  <div className="row">
                    <div className="col-auto">
                      <i
                        className="text-dark ri-mail-send-fill"
                        style={{ fontSize: "2.5rem" }}
                      />
                    </div>
                    {!isHeader && (
                      <div className="col">
                        <h6 className="mb-0 h6">24/7 Email Support</h6>
                        <a
                          href={`mailto:${Headers.email}`}
                          className="nav-link-base h6 fw-light "
                        >
                          {Headers.email}
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom border-top py-4">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12 col-lg-3 text-center text-lg-start">
                <ul className="list-inline mb-0 d-none">
                  <li className="list-inline-item">
                    <a className="link-accent fs-xl" href="#">
                      <i className="ri-facebook-line" />
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a className="link-danger fs-xl" href="#">
                      <i className="ri-google-line" />
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a className="link-info fs-xl" href="#">
                      <i className="ri-twitter-line" />
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-12 col-lg-6 text-center">
                <p
                  title="https://seotowebdesign.com/"
                  className="nav-link-base fs-sm "
                >
                  {!isHeader && Headers.footer_credit}| Design by{" "}
                  <a href="https://seotowebdesign.com/" target="blank">
                    Seo To Webdesign{" "}
                  </a>
                </p>
              </div>

              {/* <div className="col-12 col-lg-3 text-center text-lg-end mt-3 mt-lg-0">
            <img
              className="d-inline-block align-middle"
              src="assets/img/shop/cards.png"
              width={246}
              alt="Cerdit Cards"
            />
          </div> */}
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
