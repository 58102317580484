import React, { useRef, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Swiper, SwiperSlide } from "swiper/react";
import axios from "axios";
import toast from "react-hot-toast";
import { Helmet } from "react-helmet";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import axiosInstance from "../../axiosInstance";

// import required modules
import { Pagination, Navigation, Autoplay } from "swiper/modules";

import banner1 from "../assets_user/img/fullbanner1.webp";
import banner2 from "../assets_user/img/fullbanner2.webp";
import banner3 from "../assets_user/img/fullbanner3.webp";
import banner4 from "../assets_user/img/fullbanner4.webp";

import grid1 from "../assets_user/img/grid1.webp";
import grid2 from "../assets_user/img/grid2.webp";
import grid3 from "../assets_user/img/grid3.webp";
import grid4 from "../assets_user/img/grid4.webp";
import gridslide1 from "../assets_user/img/gridslide1.webp";
import gridslide2 from "../assets_user/img/gridslide2.webp";
import gridslide3 from "../assets_user/img/gridslide3.webp";
import gridslide4 from "../assets_user/img/gridslide4.webp";
import gridslide5 from "../assets_user/img/gridslide5.webp";
import CreateSlug from "../components/extra/CreateSlug";
import { useBlogContext } from "../../fetchdata/BlogContext";

const Home = () => {
  const {
    Headers,
    isHeader,
    cartItems,
    AllProducts,
    AllCategoriess,
    isLayoutLoading,
    layout,
  } = useBlogContext();

  const swiperRefLocal = useRef();

  const handleMouseEnter = () => {
    swiperRefLocal?.current?.swiper?.autoplay?.stop();
  };

  const handleMouseLeave = () => {
    swiperRefLocal?.current?.swiper?.autoplay?.start();
  };

  const [ratings, setRatings] = useState([]);

  const [isProducts, setIsProducts] = useState(true);

  const [Products, setProducts] = useState([]);

  const getRating = async () => {
    try {
      const { data } = await axiosInstance.get(`/all-rating`);
      setRatings(data.ratings);
      console.log("getRating", data);
    } catch (error) {
      console.error("Error fetching rating:", error);
    }
  };

  useEffect(() => {
    getRating();
  }, []);

  const getProducts = async () => {
    try {
      const { data } = await axiosInstance.get("/all-home-products");
      console.log("products", data);
      setProducts(data.products);
      setIsProducts(false); // Set loading state to false in case of an error
    } catch (error) {
      console.error("Error fetching products:", error);
      setIsProducts(false); // Set loading state to false in case of an error
    }
  };

  const [isLoading, setIsLoading] = useState(false);
  const [Rblogs, setRBlogs] = useState([]);

  const getAllBlogs = async () => {
    try {
      setIsLoading(false);
      const response = await axiosInstance.get("/all-blogs-latest");
      const { success, blogs, message } = response.data;
      console.log(response.data);
      if (success) {
        setRBlogs(blogs.reverse().slice(0, 8));
      } else {
        console.error("Error fetching blogs:", message);
      }
    } catch (error) {
      console.error("Error fetching blogs:", error);
    } finally {
      setIsLoading(true);
    }
  };

  useEffect(() => {
    getProducts();
    getAllBlogs();
  }, []);

  function formatDate(dateString) {
    const options = { year: "numeric", month: "short", day: "numeric" };
    return new Date(dateString).toLocaleDateString("en-US", options);
  }

  const [enquireData, setEnquireData] = useState([]);

  const [EnquireStatus, setEnquireStatus] = useState(false);

  const [EnquireShow, setEnquireShow] = useState(false);

  const enquireToggle = () => {
    if (EnquireShow) {
      setEnquireShow(false);
    } else {
      setEnquireShow(true);
    }
  };

  useEffect(() => {
    // Get the current show count from localStorage
    let showCount = parseInt(localStorage.getItem("popupShowCount"), 10) || 0;

    if (showCount < 2) {
      setEnquireShow(true); // Show the popup if it's not shown more than 2 times
    }

    // Increment the show count in localStorage
    showCount += 1;
    localStorage.setItem("popupShowCount", showCount);
  }, []);

  const capitalizeEachWord = (str) => {
    if (!str) return str;
    str = str.replace(/\d+/g, "");
    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };

  const validateEmail = (email) => {
    // Regular expression to check for invalid characters
    const invalidCharsRegex = /[^\w@_.]| /; // Disallow spaces and any character that's not a word character, @, or .

    // Check for invalid cases
    if (email === "-" || email === "+") {
      return "";
    } else if (invalidCharsRegex.test(email)) {
      return ""; // Return empty string if invalid characters (including spaces) are found
    } else {
      return email; // Return the original email if valid
    }
  };

  const handleEnquireChange = (e) => {
    const { name, value, type, checked } = e.target;

    // Determine the new value based on input type
    let newValue;

    if (type === "checkbox") {
      if (name === "interested") {
        newValue = checked
          ? [...(enquireData.interested || []), value]
          : (enquireData.interested || []).filter((item) => item !== value);
      } else {
        newValue = checked;
      }
    } else if (type === "radio") {
      newValue = value; // Directly assign the selected radio button's value
    } else {
      newValue = value; // For other inputs
    }

    // Capitalize each word for specific fields
    if (name === "fullname") {
      newValue = capitalizeEachWord(newValue);
    }

    if (name === "email") {
      newValue = validateEmail(newValue);
    }

    if (name === "phone") {
      // Replace non-digit characters
      newValue = value.replace(/\D/g, ""); // This allows only digits
      if (newValue.length > 10) {
        newValue = newValue.slice(0, 10); // Limit to 10 digits
      }
    }

    // Update form data with the new value
    setEnquireData((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));
  };

  const handleSendEnquire = async () => {
    // toast.error(`${Id}, ${userEmail}`);
    // if ((!Id, !userEmail)) {
    //   toast.error("Id or Email required");
    //   setEnquireStatus(false);
    //   return;
    // }
    const fields = [
      { name: "fullname", message: "Please enter Full Name" },
      { name: "phone", message: "Please enter phone number" },
      { name: "email", message: "Please enter email" },
      { name: "organizationName", message: "Please enter organization name" },
      { name: "designation", message: "Please enter designation" },
      { name: "interested", message: "Please enter Interested" },
    ];

    for (const field of fields) {
      if (!enquireData[field.name]) {
        toast.error(field.message);
        setEnquireStatus(false);
        return;
      }
    }

    const enqdata = {
      ...enquireData,
      userId: null,
      userEmail: null,
      type: 0,
    };

    try {
      setEnquireStatus(true);
      await toast.promise(axiosInstance.post(`/send-enquire`, enqdata), {
        loading: "Enquire Submiting...", // Loading message
        success: "Enquire Submited Successfully!", // Success message
        error: "Failed to Submit Enquire.", // Error message
      });

      setEnquireData((prevData) => ({
        ...prevData,
        fullname: "",
        email: "",
        phone: "",
        service: "",
        QTY: "",
        Requirement: "",
        organizationName: "",
        designation: "",
        interested: [],
      })); // Clear form fields

      enquireToggle();
    } catch (error) {
      console.error("Failed to send email:", error);
      // toast.error('Failed to send email');
    } finally {
      setEnquireStatus(false);
    }
  };

  const dataInterested = [
    { name: "Join Doctor Purchase Consortium" },
    { name: "NABH Accreditation" },
    { name: "Hospital Planning and Designing" },
    { name: "Convert Hospital into Green Hospital" },
    { name: "NABL Accreditation" },
  ];

  return (
    <>
      <Header />

      <Helmet>
        {Headers && Headers.meta_favicon && (
          <link rel="apple-touch-icon" href={Headers.meta_favicon} />
        )}

        <title>{Headers.meta_title}</title>
        {Headers.meta_description && (
          <meta name="description" content={Headers.meta_description} />
        )}
      </Helmet>

      <div
        className="modal enquire fade show"
        style={{
          display: EnquireShow ? "block" : "none",
        }}
        id={`exampleModalPop`}
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header py-0">
              <h5 className="modal-title" id="exampleModalLabel">
                For More Enquire
              </h5>
              <button
                type="button"
                className="border-0 close bg-transparent h3"
                data-dismiss="modal"
                aria-label="Close"
                onClick={enquireToggle}
              >
                <span aria-hidden="true" style={{ fontSize: "35px" }}>
                  ×
                </span>
              </button>
            </div>
            <div className="modal-body">
              <div className=" row">
                {/* Name input */}
                <div data-mdb-input-init="" className="form-outline mb-4">
                  <label className="form-label" htmlFor="form5Example1">
                    Full Name <span className="text-danger"> (Required )</span>
                  </label>
                  <input
                    type="text"
                    name="fullname"
                    id="form5Example1"
                    className="form-control"
                    value={enquireData.fullname}
                    onChange={handleEnquireChange}
                    style={{
                      boxShadow: "none",
                    }}
                  />
                </div>
                {/* Email input */}
                <div
                  data-mdb-input-init=""
                  className="form-outline mb-4 col-md-6"
                >
                  <label className="form-label" htmlFor="form5Example2">
                    Phone No. <span className="text-danger"> (Required )</span>
                  </label>
                  <input
                    type="number"
                    id="form5Example2"
                    className="form-control"
                    name="phone"
                    value={enquireData.phone}
                    onChange={handleEnquireChange}
                    style={{
                      boxShadow: "none",
                    }}
                  />
                </div>

                <div
                  data-mdb-input-init=""
                  className="form-outline mb-4 col-md-6"
                >
                  <label className="form-label" htmlFor="form5Example2">
                    Email address{" "}
                    <span className="text-danger"> (Required )</span>
                  </label>
                  <input
                    type="email"
                    id="form5Example2"
                    className="form-control"
                    name="email"
                    value={enquireData.email}
                    onChange={handleEnquireChange}
                    style={{
                      boxShadow: "none",
                    }}
                  />
                </div>

                <div data-mdb-input-init="" className="form-outline mb-4">
                  <label className="form-label" htmlFor="organizationName">
                    Organization Name
                    <span className="text-danger"> (Required )</span>
                  </label>
                  <input
                    type="text"
                    id="organizationName"
                    className="form-control"
                    name="organizationName"
                    value={enquireData.organizationName}
                    onChange={handleEnquireChange}
                    style={{
                      boxShadow: "none",
                    }}
                  />
                </div>

                <div data-mdb-input-init="" className="form-outline mb-4">
                  <label className="form-label" htmlFor="Designation">
                    Designation
                    <span className="text-danger"> (Required )</span>
                  </label>
                  <input
                    type="text"
                    id="Designation"
                    className="form-control"
                    name="designation"
                    value={enquireData.designation}
                    onChange={handleEnquireChange}
                    style={{
                      boxShadow: "none",
                    }}
                  />
                </div>
                <div data-mdb-input-init="" className="form-outline mb-4">
                  <label className="form-label" htmlFor="interested">
                    Interested In
                    <span className="text-danger"> (Required )</span>
                  </label>

                  {dataInterested.map((item) => (
                    <div className="form-check d-block">
                      <input
                        className="form-check-input me-2"
                        type="checkbox"
                        name="interested"
                        id={item.name}
                        value={item.name}
                        onChange={handleEnquireChange}
                        defaultChecked={
                          enquireData.interested &&
                          enquireData.interested.includes(item.name)
                        } // Check if item is in the specialization array
                      />

                      <label
                        type="button"
                        className={`form-check-label`}
                        htmlFor={item.name}
                      >
                        {item.name}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={enquireToggle}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => handleSendEnquire()} // Wrap the function in an anonymous function
                disabled={EnquireStatus} // Properly use the `disabled` attribute
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>

      <main className="page ">
        {/* Hero Section */}
        <div className="container mt-4 ">
          <div className="row">
            <div className="col-md-12 mt-sm-4">
              <div
                className="hero hero-swiper"
                style={{
                  width: 1400,
                  maxWidth: "100% ",
                  height: "auto",
                  aspectRatio: "1400/400",
                  borderRadius: 15,
                  overflow: "hidden",
                }}
              >
                {isLayoutLoading ? (
                  <>
                    <div
                      className="skeleton w-100"
                      style={{
                        width: 1500,
                        maxWidth: "100% ",
                        height: "auto",
                        aspectRatio: "1400/400",
                      }}
                    ></div>
                  </>
                ) : (
                  <div
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <Swiper
                      pagination={true}
                      navigation={true}
                      gap={"30"}
                      ref={swiperRefLocal}
                      autoplay={{
                        delay: 1500,
                        pauseOnMouseEnter: true,
                      }}
                      loop={true}
                      modules={[Pagination, Navigation, Autoplay]}
                      className="swiper-wrapper"
                      style={{
                        width: 1400,
                        maxWidth: "100% ",
                        height: "auto",
                        aspectRatio: "1400/400",
                        borderRadius: 15,
                        overflow: "hidden",
                      }}
                    >
                      {layout.home_slider && (
                        <>
                          {layout.home_slider.map((image, index) => (
                            <SwiperSlide key={index}>
                              <img
                                className="img-fluid w-100"
                                src={image}
                                alt="Product"
                              />
                            </SwiperSlide>
                          ))}
                        </>
                      )}
                    </Swiper>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* Hero Section */}

        <div className="container mt-4 hero-swiper ">
          <Swiper
            breakpoints={{
              300: {
                slidesPerView: 2,
                spaceBetween: 10, // Set the gap between slides for window width <= 400px
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 15, // Set the gap between slides for window width <= 768px
              },
              992: {
                slidesPerView: 5,
                spaceBetween: 15, // Set the gap between slides for window width <= 992px
              },
              1200: {
                slidesPerView: 8,
                spaceBetween: 20, // Set the gap between slides for window width <= 1200px
              },
            }}
            pagination={true}
            modules={[Pagination, Navigation]}
            className="swiper-wrapper  "
          >
            {isLayoutLoading
              ? Array.from({ length: 8 }).map((_, index) => (
                  <SwiperSlide key={index}>
                    <div
                      className="skeleton w-100"
                      style={{
                        width: "100%",
                        maxWidth: "100%",
                        height: "auto",
                        aspectRatio: "1/1",
                      }}
                    ></div>
                  </SwiperSlide>
                ))
              : layout.trending_product_banner && (
                  <>
                    {layout.trending_product_banner.map((image, index) => (
                      <SwiperSlide>
                        <div
                          className="border rounded shadow p-2 text-center"
                          style={{
                            width: "100%",
                            height: "100%",
                            orderRadius: 10,
                            aspectRatio: "1/1",
                          }}
                        >
                          <Link to={image.imageUrlInput} className="text-dark">
                            <img
                              src={image.imageInput}
                              width="100"
                              alt={image.imageTITInput}
                              className="px-4 pt-2 mx-auto mb-2"
                            />
                            <p
                              className="text-center m-0 "
                              style={{
                                display: "-webkit-box",
                                WebkitLineClamp: 2,
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {image.imageTITInput}
                            </p>
                          </Link>
                        </div>
                      </SwiperSlide>
                    ))}
                  </>
                )}
          </Swiper>
        </div>

        {isLayoutLoading
          ? Array.from({ length: 1 }).map((_, index) => (
              <div className="container my-4 py-2 d-block">
                <div
                  className="skeleton w-100"
                  style={{
                    width: "100%",
                    maxWidth: "100%",
                    height: "auto",
                    aspectRatio: "1/0.1",
                  }}
                ></div>
              </div>
            ))
          : layout.trending_product_carousal && (
              <>
                {layout.trending_product_carousal.map((image, index) => (
                  <Link
                    key={index}
                    to={image.imageUrlInput}
                    className="container my-4 py-2 d-block"
                  >
                    <img
                      src={image.imageInput}
                      width="100%"
                      alt=""
                      className="w-100"
                    />
                  </Link>
                ))}
              </>
            )}

        <div className="container my-2 mt-4 ">
          <div className="row p-0  rounded">
            {isLayoutLoading
              ? Array.from({ length: 4 }).map((_, index) => (
                  <div className=" col-md-3 mb-4">
                    <div
                      className="skeleton w-100"
                      style={{
                        width: "100%",
                        maxWidth: "100%",
                        height: "auto",
                        aspectRatio: "1/0.22",
                      }}
                    ></div>
                  </div>
                ))
              : layout.latest_product_banner && (
                  <>
                    {layout.latest_product_banner.map((image, index) => (
                      <Link
                        className=" col-md-3 mb-4 text-dark"
                        to={image.imageUrlInput}
                      >
                        <div
                          className="d-flex px-3 py-3 d-flex align-items-center gap-2 rounded blurbbg"
                          style={{
                            background: "rgb(233, 250, 238)",
                            height: "100%",
                          }}
                        >
                          <img
                            alt={image.imageTITInput}
                            width="40px"
                            height="40px"
                            src={image.imageInput}
                          />

                          <div className="col">
                            <h6 className="text-dark">
                              {image.imageTITInput}{" "}
                            </h6>
                            <p
                              className="fw-light text-grey p-0 m-0"
                              style={{ fontSize: 14 }}
                            >
                              {" "}
                              {image.imageParaInput}{" "}
                            </p>
                          </div>
                          <i class="ri-arrow-right-s-line h4 m-0" />
                        </div>
                      </Link>
                    ))}
                  </>
                )}
          </div>
        </div>

        <div
          className={`container my-2 mt-4 ${
            layout && layout.latest_product_carousal?.length === 0 && "d-none"
          } `}
        >
          <div className="row">
            <div className="col-12">
              <h2> Service Category</h2>
            </div>
          </div>
          <div className="row py-2  justify-content-center ">
            {isLayoutLoading
              ? Array.from({ length: 6 }).map((_, index) => (
                  <div className="col-md-2 col-6 mb-2">
                    <div
                      className="skeleton w-100"
                      style={{
                        width: "100%",
                        maxWidth: "100%",
                        height: "auto",
                        aspectRatio: "1/1.2",
                      }}
                    ></div>
                  </div>
                ))
              : layout.latest_product_carousal && (
                  <>
                    {layout.latest_product_carousal.map((image, index) => (
                      <div className="col-md-2 col-6 mb-2" key={index}>
                        <div className="p-0 bg-light-blue rounded">
                          <Link className="col py-2 " to={image.imageUrlInput}>
                            <img
                              style={{
                                width: "100%",

                                objectFit: "cover",
                                aspectRatio: "1/1",
                              }}
                              alt={image.imageTITInput}
                              width="100%"
                              className="rounded"
                              src={image.imageInput}
                            />

                            <h5 className="text-center text-primary fs-lg px-2 py-2">
                              {" "}
                              {image.imageTITInput}{" "}
                            </h5>
                          </Link>
                        </div>
                      </div>
                    ))}
                  </>
                )}
          </div>
        </div>

        <div className="container mt-4">
          <Swiper
            breakpoints={{
              300: {
                slidesPerView: 1,
                spaceBetween: 10, // Set the gap between slides for window width <= 400px
              },
              768: {
                slidesPerView: 1,
                spaceBetween: 15, // Set the gap between slides for window width <= 768px
              },
              992: {
                slidesPerView: 2,
                spaceBetween: 15, // Set the gap between slides for window width <= 992px
              },
              1200: {
                slidesPerView: 2,
                spaceBetween: 20, // Set the gap between slides for window width <= 1200px
              },
            }}
            pagination={true}
            modules={[Pagination, Navigation]}
            className="swiper-wrapper"
          >
            {isLayoutLoading
              ? Array.from({ length: 3 }).map((_, index) => (
                  <SwiperSlide>
                    <div
                      className="skeleton w-100"
                      style={{
                        width: "100%",
                        maxWidth: "100%",
                        height: "auto",
                        aspectRatio: "1/0.3",
                      }}
                    ></div>
                  </SwiperSlide>
                ))
              : layout.service_banner_images && (
                  <>
                    {layout.service_banner_images.map((image, index) => (
                      <SwiperSlide
                        key={index}
                        className="container my-4 py-2 d-block"
                      >
                        <Link to={image.imageUrlInput} className="w-100s">
                          <img
                            src={image.imageInput}
                            width="100%"
                            alt=""
                            className="w-100"
                          />
                        </Link>
                      </SwiperSlide>
                    ))}
                  </>
                )}
          </Swiper>
        </div>

        <div className="container my-2 mt-4">
          <div className="row align-items-start">
            <div className="col-md-5 whysticky">
              <img
                alt=""
                src={layout.collection_img}
                className="rounded"
                width="100%"
                style={{
                  width: "100%",
                  maxWidth: "100%",
                  minHeight: 400,
                  objectFit: "cover",
                }}
              />
            </div>
            <div className="col-md-7 d-flex flex-column justify-content-center align-items-start mt-2">
              {isLayoutLoading ? (
                Array.from({ length: 1 }).map((_, index) => (
                  <div
                    className="skeleton w-100 mb-2"
                    style={{ width: "100%", maxWidth: "100%", height: 35 }}
                  ></div>
                ))
              ) : (
                <>
                  <h1> {layout.collection_heading}</h1>

                  <div
                    dangerouslySetInnerHTML={{
                      __html: layout.collection_paragraph,
                    }}
                  />

                  {layout.collection_url !== "" && (
                    <Link
                      to={layout.collection_url}
                      class="btn btn-thin btn-md  btn-dark rounded-xl"
                      type="button"
                    >
                      know More
                    </Link>
                  )}
                </>
              )}
            </div>
          </div>
          <br />
        </div>

        <div className="container mt-4 hero-swiper ">
          <Swiper
            breakpoints={{
              300: {
                slidesPerView: 1,
                spaceBetween: 10, // Set the gap between slides for window width <= 400px
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 15, // Set the gap between slides for window width <= 768px
              },
              992: {
                slidesPerView: 3,
                spaceBetween: 15, // Set the gap between slides for window width <= 992px
              },
              1200: {
                slidesPerView: 4,
                spaceBetween: 20, // Set the gap between slides for window width <= 1200px
              },
            }}
            pagination={true}
            navigation={true}
            modules={[Pagination, Navigation]}
            className="swiper-wrapper"
          >
            {isLayoutLoading
              ? Array.from({ length: 8 }).map((_, index) => (
                  <SwiperSlide key={index}>
                    <div
                      className="skeleton w-100"
                      style={{
                        width: "100%",
                        maxWidth: "100%",
                        height: "auto",
                        aspectRatio: "1/1",
                      }}
                    ></div>
                  </SwiperSlide>
                ))
              : layout.trending_product_banner && (
                  <>
                    <SwiperSlide>
                      <div className="card d-flex flex-column p-4 shadow ">
                        {" "}
                        <div className="mt-2">
                          {" "}
                          <i class="ri-star-fill" style={{ color: "gold" }}></i>
                          <i class="ri-star-fill" style={{ color: "gold" }}></i>
                          <i class="ri-star-fill" style={{ color: "gold" }}></i>
                          <i class="ri-star-fill" style={{ color: "gold" }}></i>
                          <i class="ri-star-fill" style={{ color: "gold" }}></i>
                          <span className="fas fa-star-half-alt active-star" />{" "}
                        </div>{" "}
                        <div className="main font-weight-bold pb-2 pt-1">
                          Great Service
                        </div>{" "}
                        <div className="testimonial">
                          {" "}
                          Thank you for all your wonderful contributions during
                          our NABH Certification process. It was really helpful
                          to implement your input for quality improvement. We
                          already seeing improvement in efficiency. I really
                          appreciate your guidance & streamlined process with me
                          to help our staff.
                        </div>{" "}
                        <div className="d-flex flex-row profile pt-4 mt-auto gap-4  align-items-center">
                          {" "}
                          <img
                            src="/assets/img/Dr.-Ajay-Gupta-MBBS-DCH-MDAM-MAGSUSA.jpg"
                            alt=""
                            width="80"
                            style={{ aspectRatio: "1/1" }}
                            className="rounded-circle"
                          />{" "}
                          <div className="d-flex flex-column pl-2 align-items-center">
                            {" "}
                            <div className="name">
                              Dr. Ajay Gupta - MBBS, DCH, MD(AM), MAGS(USA)
                            </div>{" "}
                            <p className="text-muted designation">
                              Ajay Pratima Hospital, Breilly
                            </p>{" "}
                          </div>{" "}
                        </div>{" "}
                      </div>
                    </SwiperSlide>

                    <SwiperSlide>
                      <div className="card d-flex flex-column p-4 shadow ">
                        {" "}
                        <div className="mt-2">
                          {" "}
                          <i class="ri-star-fill" style={{ color: "gold" }}></i>
                          <i class="ri-star-fill" style={{ color: "gold" }}></i>
                          <i class="ri-star-fill" style={{ color: "gold" }}></i>
                          <i class="ri-star-fill" style={{ color: "gold" }}></i>
                          <i class="ri-star-fill" style={{ color: "gold" }}></i>
                          <span className="fas fa-star-half-alt active-star" />{" "}
                        </div>{" "}
                        <div className="main font-weight-bold pb-2 pt-1">
                          Great Service
                        </div>{" "}
                        <div className="testimonial">
                          {" "}
                          I thank you for your assistance during the NABH
                          process. It is difficult to manage the NABH work alone
                          in the absence of your team. I would highly recommend
                          your Company to our friends & Colleagues. I really
                          wish to see you working at large scale with
                          reputation.
                        </div>{" "}
                        <div className="d-flex flex-row profile pt-4 mt-auto gap-4  align-items-center">
                          {" "}
                          <img
                            src="/assets/img/Dr.-Ashok-Mendiratta-MBBS-MD.png"
                            alt=""
                            width="80"
                            style={{ aspectRatio: "1/1" }}
                            className="rounded-circle"
                          />{" "}
                          <div className="d-flex flex-column pl-2">
                            {" "}
                            <div className="name">
                              Dr. Ashok Mendiratta (MBBS, MD)
                            </div>{" "}
                            <p className="text-muted designation">
                              Child Specialist, Bareilly
                            </p>{" "}
                          </div>{" "}
                        </div>{" "}
                      </div>
                    </SwiperSlide>

                    <SwiperSlide>
                      <div className="card d-flex flex-column p-4 shadow ">
                        {" "}
                        <div className="mt-2">
                          {" "}
                          <i class="ri-star-fill" style={{ color: "gold" }}></i>
                          <i class="ri-star-fill" style={{ color: "gold" }}></i>
                          <i class="ri-star-fill" style={{ color: "gold" }}></i>
                          <i class="ri-star-fill" style={{ color: "gold" }}></i>
                          <i class="ri-star-fill" style={{ color: "gold" }}></i>
                          <span className="fas fa-star-half-alt active-star" />{" "}
                        </div>{" "}
                        <div className="main font-weight-bold pb-2 pt-1">
                          Great Service
                        </div>{" "}
                        <div className="testimonial">
                          {" "}
                          I want to take a moment to thank you for your support,
                          guidance and encouragement during NABH certification
                          process. Your continuing support & encouragement are
                          appreciated more than you recognize it.
                        </div>
                        <div className="d-flex flex-row profile pt-4 mt-auto gap-4  align-items-center">
                          {" "}
                          <img
                            src="/assets/img/Dr.-Somesh-Mehrotra-MD-Medicine-Director.png"
                            alt=""
                            width="80"
                            style={{ aspectRatio: "1/1" }}
                            className="rounded-circle"
                          />{" "}
                          <div className="d-flex flex-column pl-2">
                            {" "}
                            <div className="name">
                              Dr. Somesh Mehrotra (MD Medicine - Director)
                            </div>{" "}
                            <p className="text-muted designation">
                              Deepmala Hospital, Chupla Breilly UP
                            </p>{" "}
                          </div>{" "}
                        </div>{" "}
                      </div>
                    </SwiperSlide>

                    <SwiperSlide>
                      <div className="card d-flex flex-column p-4 shadow ">
                        {" "}
                        <div className="mt-2">
                          {" "}
                          <i class="ri-star-fill" style={{ color: "gold" }}></i>
                          <i class="ri-star-fill" style={{ color: "gold" }}></i>
                          <i class="ri-star-fill" style={{ color: "gold" }}></i>
                          <i class="ri-star-fill" style={{ color: "gold" }}></i>
                          <i class="ri-star-fill" style={{ color: "gold" }}></i>
                          <span className="fas fa-star-half-alt active-star" />{" "}
                        </div>{" "}
                        <div className="main font-weight-bold pb-2 pt-1">
                          Great Service
                        </div>{" "}
                        <div className="testimonial">
                          {" "}
                          We admire your valuable contribution in NABH/NABL
                          Accreditation, Business Promotion, Hospital Expansion,
                          Hospital Management, Staff Training and TPA/Panel
                          Supervision work.{" "}
                        </div>
                        <div className="d-flex flex-row profile pt-4 mt-auto gap-4  align-items-center">
                          <img
                            src="/assets/img/Dr.-Anil-Goyal-Medical-Director.jpeg"
                            alt=""
                            width="80"
                            style={{ aspectRatio: "1/1" }}
                            className="rounded-circle"
                          />{" "}
                          <div className="d-flex flex-column pl-2">
                            {" "}
                            <div className="name">
                              Dr. Anil Goyal (Medical Director)
                            </div>{" "}
                            <p className="text-muted designation">
                              Goyal Hospital & Urology Centre Delhi
                            </p>{" "}
                          </div>{" "}
                        </div>{" "}
                      </div>
                    </SwiperSlide>

                    <SwiperSlide>
                      <div className="card d-flex flex-column p-4 shadow ">
                        {" "}
                        <div className="mt-2">
                          {" "}
                          <i class="ri-star-fill" style={{ color: "gold" }}></i>
                          <i class="ri-star-fill" style={{ color: "gold" }}></i>
                          <i class="ri-star-fill" style={{ color: "gold" }}></i>
                          <i class="ri-star-fill" style={{ color: "gold" }}></i>
                          <i class="ri-star-fill" style={{ color: "gold" }}></i>
                          <span className="fas fa-star-half-alt active-star" />{" "}
                        </div>{" "}
                        <div className="testimonial">
                          {" "}
                          Hospital Traders team members guided us very well
                          throughout our NABH preparation period. They are well
                          conversant with their job.{" "}
                        </div>
                        <div className="d-flex flex-row profile pt-4 mt-auto gap-4  align-items-center">
                          <img
                            src="/assets/img/Dr.-Jagjit-Ahuja-Medical-Director.jpg"
                            alt=""
                            width="80"
                            style={{ aspectRatio: "1/1" }}
                            className="rounded-circle"
                          />{" "}
                          <div className="d-flex flex-column pl-2">
                            {" "}
                            <div className="name">
                              Dr. Jagjit Ahuja (Medical Director)
                            </div>{" "}
                            <p className="text-muted designation">
                              Ahuja Hospital Pvt. Ltd. Panipat, Haryana
                            </p>{" "}
                          </div>{" "}
                        </div>{" "}
                      </div>
                    </SwiperSlide>

                    <SwiperSlide>
                      <div className="card d-flex flex-column p-4 shadow ">
                        {" "}
                        <div className="mt-2">
                          {" "}
                          <i class="ri-star-fill" style={{ color: "gold" }}></i>
                          <i class="ri-star-fill" style={{ color: "gold" }}></i>
                          <i class="ri-star-fill" style={{ color: "gold" }}></i>
                          <i class="ri-star-fill" style={{ color: "gold" }}></i>
                          <i class="ri-star-fill" style={{ color: "gold" }}></i>
                          <span className="fas fa-star-half-alt active-star" />{" "}
                        </div>{" "}
                        <div className="testimonial">
                          {" "}
                          We sincerely appreciate your guidance given on
                          documentation and implementations of NABH Standards.
                          Training given by you and your team has given a new
                          perspective in work culture of Hospital.{" "}
                        </div>
                        <div className="d-flex flex-row profile pt-4 mt-auto gap-4  align-items-center">
                          <img
                            src="/assets/img/Dr.-S.-L.-Gupta-Medical-Superintendent.jpg"
                            alt=""
                            width="80"
                            style={{ aspectRatio: "1/1" }}
                            className="rounded-circle"
                          />{" "}
                          <div className="d-flex flex-column pl-2">
                            {" "}
                            <div className="name">
                              Dr. S. L. Gupta (Medical Superintendent)
                            </div>{" "}
                            <p className="text-muted designation">
                              R. S. Grover Memorial Hospital, Delhi
                            </p>{" "}
                          </div>{" "}
                        </div>{" "}
                      </div>
                    </SwiperSlide>

                    <SwiperSlide>
                      <div className="card d-flex flex-column p-4 shadow ">
                        {" "}
                        <div className="mt-2">
                          {" "}
                          <i class="ri-star-fill" style={{ color: "gold" }}></i>
                          <i class="ri-star-fill" style={{ color: "gold" }}></i>
                          <i class="ri-star-fill" style={{ color: "gold" }}></i>
                          <i class="ri-star-fill" style={{ color: "gold" }}></i>
                          <i class="ri-star-fill" style={{ color: "gold" }}></i>
                          <span className="fas fa-star-half-alt active-star" />{" "}
                        </div>{" "}
                        <div className="testimonial">
                          {" "}
                          I am fully satisfied with his services. I wish for New
                          Quality Consultancy Services Success in future
                          endeavors.{" "}
                        </div>
                        <div className="d-flex flex-row profile pt-4 mt-auto gap-4  align-items-center">
                          <img
                            src="/assets/img/Dr.-Mahesh-Agrawal-Medical-Director.jpg"
                            alt=""
                            width="80"
                            style={{ aspectRatio: "1/1" }}
                            className="rounded-circle"
                          />{" "}
                          <div className="d-flex flex-column pl-2">
                            {" "}
                            <div className="name">
                              Dr. Mahesh Agrawal (Medical Director)
                            </div>{" "}
                            <p className="text-muted designation">
                              Mahesh Hospital, Delhi
                            </p>{" "}
                          </div>{" "}
                        </div>{" "}
                      </div>
                    </SwiperSlide>
                  </>
                )}
          </Swiper>
        </div>

        <div className="container mt-4">
          <Swiper
            breakpoints={{
              300: {
                slidesPerView: 3,
                spaceBetween: 10, // Set the gap between slides for window width <= 400px
              },
              768: {
                slidesPerView: 4,
                spaceBetween: 15, // Set the gap between slides for window width <= 768px
              },
              992: {
                slidesPerView: 6,
                spaceBetween: 15, // Set the gap between slides for window width <= 992px
              },
              1200: {
                slidesPerView: 6,
                spaceBetween: 20, // Set the gap between slides for window width <= 1200px
              },
            }}
            pagination={true}
            modules={[Pagination, Navigation]}
            className="swiper-wrapper"
          >
            {isLayoutLoading
              ? Array.from({ length: 6 }).map((_, index) => (
                  <SwiperSlide>
                    <div
                      className="skeleton w-100"
                      style={{
                        width: "100%",
                        maxWidth: "100%",
                        height: "auto",
                        aspectRatio: "1/0.4",
                      }}
                    ></div>
                  </SwiperSlide>
                ))
              : layout.service_logos && (
                  <>
                    {layout.service_logos.length > 1 &&
                      layout.service_logos.map((image, index) => (
                        <SwiperSlide
                          key={index}
                          to={image.imageUrlInput}
                          className="container my-4 py-2 d-block"
                        >
                          <Link to={image.imageUrlInput}>
                            <img
                              src={image.imageInput}
                              width="100%"
                              alt=""
                              className="w-100"
                            />
                          </Link>
                        </SwiperSlide>
                      ))}
                  </>
                )}
          </Swiper>
        </div>

        {isLayoutLoading
          ? Array.from({ length: 1 }).map((_, index) => (
              <div className="container mb-4 pb-2 d-block">
                <div
                  className="skeleton w-100"
                  style={{
                    width: "100%",
                    maxWidth: "100%",
                    height: "auto",
                    aspectRatio: "1/0.1",
                  }}
                ></div>
              </div>
            ))
          : layout.service_category_Images && (
              <>
                {layout.service_category_Images.length > 1 &&
                  layout.service_category_Images.map((image, index) => (
                    <Link
                      key={index}
                      to={image.imageUrlInput}
                      className="container my-4 py-2 d-block"
                    >
                      <img
                        src={image.imageInput}
                        width="100%"
                        alt=""
                        className="w-100"
                      />
                    </Link>
                  ))}
              </>
            )}

        {/* Product Cards */}
        <div className="container pt-0 pt-sm-5 d-none">
          {/* Heading */}
          <div className="d-none d-flex flex-wrap justify-content-between align-items-center pt-1 border-bottom pb-4 mb-4">
            {/* <h2 className="h3 mb-0 me-2">Trending products</h2> */}
            <div className="ms-n4">
              {/* <a
                className="btn btn-sm btn-link link-info link-hover-primary d-flex align-items-center bg-btn-new"
                href="#"
              >
                View All
                <i className="ri-arrow-right-line ms-1" />
              </a> */}
            </div>
          </div>
          {/* Heading */}
          {/* Product Cards */}
          <div className="row pt-2 mx-n2 hero-swiper hide-desk-arrow">
            {/* Product Card */}
            <Swiper
              breakpoints={{
                300: {
                  slidesPerView: 2,
                  spaceBetween: 10, // Set the gap between slides for window width <= 400px
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 20, // Set the gap between slides for window width <= 768px
                },
                992: {
                  slidesPerView: 4,
                  spaceBetween: 25, // Set the gap between slides for window width <= 992px
                },
                1200: {
                  slidesPerView: 4,
                  spaceBetween: 20, // Set the gap between slides for window width <= 1200px
                },
              }}
              pagination={true}
              modules={[Pagination, Navigation]}
              className="swiper-wrapper"
            >
              {isProducts
                ? Array.from({ length: 7 }).map((_, index) => (
                    <SwiperSlide key={index}>
                      <div
                        className="card-1 skeleton"
                        style={{ height: 371, borderRadius: 10 }}
                      ></div>
                    </SwiperSlide>
                  ))
                : layout.trending_product && (
                    <>
                      {Products.map((product, index) => {
                        const productRatings = ratings.filter(
                          (rating) => rating.productId === product._id
                        );
                        const totalRatings = productRatings.length;
                        const totalRatingValue = productRatings.reduce(
                          (acc, curr) => acc + curr.rating,
                          0
                        );
                        const averageRating =
                          totalRatings > 0
                            ? totalRatingValue / totalRatings
                            : 0;
                        const myslug = CreateSlug(product.slug);

                        return (
                          layout.trending_product.includes(product._id) && (
                            <>
                              <SwiperSlide key={index}>
                                <div className="card card-product h-100">
                                  {/* Buttons */}
                                  <div className="product-buttons">
                                    <button
                                      className="btn-product btn-wishlist"
                                      type="button"
                                      data-bs-toggle="button"
                                      title="Add to wishlist"
                                    >
                                      <i className="ri-heart-line" />
                                    </button>
                                    <a
                                      className="btn-product btn-compare"
                                      href="#"
                                      title="Compare product"
                                    >
                                      <i className="ri-repeat-line" />
                                    </a>
                                    <a
                                      className="btn-product btn-view"
                                      href="#modal-quick-view"
                                      data-bs-toggle="modal"
                                      title="Quick preview"
                                    >
                                      <i className="ri-eye-line" />
                                    </a>
                                  </div>
                                  {/* Buttons */}
                                  {/* Preview Image */}
                                  <Link
                                    className="card-img-top d-block overflow-hidden flex-shrink-0"
                                    to={`/product/${myslug}/${product._id}`}
                                  >
                                    <img
                                      className="img-fluid"
                                      src={product.pImage}
                                      alt={`${product.title} Product Image`}
                                    />
                                  </Link>
                                  {/* Preview Image */}
                                  <div className="card-body d-flex flex-column align-items-start flex-grow-1 rounded-bottom h-100 py-3">
                                    {/* Product Category */}

                                    {/* Product Category */}
                                    {/* Product Title */}
                                    <h3 className="product-title flex-grow-1">
                                      <Link
                                        to={`/product/${myslug}/${product._id}`}
                                      >
                                        {" "}
                                        {product.title}{" "}
                                      </Link>
                                    </h3>
                                    {/* Product Title */}
                                    {/* Star Rating */}
                                    <span
                                      className={`star-rating star-${
                                        Math.round(averageRating) * 2
                                      }`}
                                    />

                                    {/* Star Rating */}
                                    {/* Product Price */}
                                    <div className="product-price">
                                      <span className="text-danger fs-5">
                                        <b className="fw-medium">
                                          {" "}
                                          ₹{product.salePrice}{" "}
                                        </b>
                                        <del className="text-body-secondary ms-1">
                                          <small>
                                            ₹{product.regularPrice}{" "}
                                          </small>
                                        </del>
                                      </span>
                                    </div>
                                    {/* Product Price */}
                                    {/* Product Meta */}

                                    <div className="d-flex gap-2 mb-2">
                                      {product.variations.map((variation) => {
                                        if (variation.name === "Color") {
                                          return variation.value.map(
                                            (value, idx) => {
                                              const firstVisibleProduct =
                                                product.variant_products.find(
                                                  (product) =>
                                                    product.Color === value &&
                                                    product.images.length !== 0
                                                );
                                              const firstImage =
                                                firstVisibleProduct?.images[0]
                                                  .src;
                                              const visibleVariant =
                                                product.variant_products.find(
                                                  (variant) => variant.visible
                                                );

                                              return (
                                                <button
                                                  key={idx}
                                                  className="border-none p-0 rounded-circle"
                                                >
                                                  <img
                                                    src={firstImage}
                                                    className="rounded-circle"
                                                    title={value}
                                                    style={{
                                                      objectFit:
                                                        "cover !important",
                                                      aspectRatio: "1/1",
                                                      width: 35,
                                                    }}
                                                    onClick={(event) => {
                                                      const productImage =
                                                        event.target
                                                          .closest(
                                                            ".card-product"
                                                          )
                                                          .querySelector(
                                                            ".card-img-top img"
                                                          );
                                                      const visibleVariantSale =
                                                        event.target
                                                          .closest(
                                                            ".card-product"
                                                          )
                                                          .querySelector(
                                                            ".product-price span b"
                                                          );
                                                      const visibleVariantRegular =
                                                        event.target
                                                          .closest(
                                                            ".card-product"
                                                          )
                                                          .querySelector(
                                                            ".product-price span del"
                                                          );

                                                      if (visibleVariantSale) {
                                                        visibleVariantSale.innerText = `₹${firstVisibleProduct.sale_price}`;
                                                      }
                                                      if (
                                                        visibleVariantRegular
                                                      ) {
                                                        visibleVariantRegular.innerText = `₹${firstVisibleProduct.regular_price}`;
                                                      }
                                                      if (productImage) {
                                                        productImage.src =
                                                          firstImage;
                                                      }
                                                    }}
                                                    onLoad={(event) => {
                                                      const visibleVariantSale =
                                                        event.target
                                                          .closest(
                                                            ".card-product"
                                                          )
                                                          .querySelector(
                                                            ".product-price span b"
                                                          );
                                                      const visibleVariantRegular =
                                                        event.target
                                                          .closest(
                                                            ".card-product"
                                                          )
                                                          .querySelector(
                                                            ".product-price span del"
                                                          );

                                                      if (visibleVariantSale) {
                                                        visibleVariantSale.innerText = `₹${visibleVariant.sale_price}`;
                                                      }
                                                      if (
                                                        visibleVariantRegular
                                                      ) {
                                                        visibleVariantRegular.innerText = `₹${visibleVariant.regular_price}`;
                                                      }
                                                    }}
                                                  />{" "}
                                                </button>
                                              );
                                            }
                                          );
                                        } else {
                                          return null; // If the name is not "Color", don't render anything
                                        }
                                      })}
                                    </div>

                                    <span className="product-meta text-body-secondary fs-xs">
                                      {" "}
                                      {product.stock === 0 ? (
                                        <span className="text-danger">
                                          Out of stock
                                        </span>
                                      ) : product.stock <= 10 ? (
                                        <span>
                                          Only {product.stock} left in stock
                                        </span>
                                      ) : (
                                        <></>
                                      )}
                                    </span>
                                    {/* Product Meta */}
                                  </div>
                                  {/* Product Addon */}

                                  {/* Product Addon */}
                                </div>
                                {/* Product Cards */}
                              </SwiperSlide>
                            </>
                          )
                        );
                      })}
                    </>
                  )}
            </Swiper>
          </div>
          {/* Product Cards */}
        </div>
        {/* Product Cards */}

        <div className="container pt-0 pt-sm-5 d-none">
          <div className="row g-3 py-0">
            {isLayoutLoading ? (
              <>
                <div
                  className="col-md-6 skeleton "
                  style={{ height: 380, borderRadius: 10 }}
                ></div>{" "}
                <div
                  className="col-md-6 skeleton "
                  style={{ height: 380, borderRadius: 10 }}
                ></div>
              </>
            ) : (
              layout.trending_product_banner && (
                <>
                  {layout.trending_product_banner.map((banner, index) => (
                    <Link
                      class="col-md-6"
                      key={index}
                      to={banner.imageUrlInput}
                    >
                      <img src={banner.imageInput} className="w-100 rounded" />
                    </Link>
                  ))}
                </>
              )
            )}
          </div>

          <div className="row mt-4 pt-2 mx-n2 hero-swiper ">
            <Swiper
              breakpoints={{
                300: {
                  slidesPerView: 2,
                  spaceBetween: 10, // Set the gap between slides for window width <= 400px
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 20, // Set the gap between slides for window width <= 768px
                },
                992: {
                  slidesPerView: 4,
                  spaceBetween: 25, // Set the gap between slides for window width <= 992px
                },
                1200: {
                  slidesPerView: 5,
                  spaceBetween: 25, // Set the gap between slides for window width <= 1200px
                },
              }}
              navigation={false}
              pagination={true}
              modules={[Pagination, Navigation]}
              className="swiper-wrapper"
            >
              {isLayoutLoading
                ? Array.from({ length: 7 }).map((_, index) => (
                    <SwiperSlide key={index}>
                      <div
                        className="card-1 skeleton"
                        style={{ height: 355, borderRadius: 10 }}
                      ></div>
                    </SwiperSlide>
                  ))
                : layout.trending_product_carousal && (
                    <>
                      {layout.trending_product_carousal.map(
                        (carousal, index) => (
                          <SwiperSlide key={index}>
                            <Link to={carousal.imageUrlInput}>
                              <img
                                src={carousal.imageInput}
                                className="w-100 rounded"
                              />
                            </Link>
                          </SwiperSlide>
                        )
                      )}
                    </>
                  )}
            </Swiper>
          </div>
        </div>

        {/* Product Cards */}
        <div className="container pt-0 pt-sm-5 d-none">
          {/* Heading */}
          <div className="d-flex flex-wrap justify-content-between align-items-center pt-1 border-bottom pb-4 mb-4">
            <h2 className="h3 mb-0 me-2">Best Selling Products</h2>
            <div className="ms-n4">
              {/* <a
                className="btn btn-sm btn-link link-info link-hover-primary d-flex align-items-center bg-btn-new"
                href="#"
              >
                View All
                <i className="ri-arrow-right-line ms-1" />
              </a> */}
            </div>
          </div>
          {/* Heading */}
          {/* Product Cards */}

          <div className="row pt-2 mx-n2 hero-swiper hide-desk-arrow">
            {/* Product Card */}
            <Swiper
              breakpoints={{
                300: {
                  slidesPerView: 2,
                  spaceBetween: 10, // Set the gap between slides for window width <= 400px
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 20, // Set the gap between slides for window width <= 768px
                },
                992: {
                  slidesPerView: 3,
                  spaceBetween: 25, // Set the gap between slides for window width <= 992px
                },
                1200: {
                  slidesPerView: 4,
                  spaceBetween: 25, // Set the gap between slides for window width <= 1200px
                },
              }}
              pagination={true}
              modules={[Pagination, Navigation]}
              className="swiper-wrapper"
            >
              {isProducts
                ? Array.from({ length: 7 }).map((_, index) => (
                    <SwiperSlide key={index}>
                      <div
                        className="card-1 skeleton"
                        style={{ height: 371, borderRadius: 10 }}
                      ></div>
                    </SwiperSlide>
                  ))
                : layout.best_selling_laptop && (
                    <>
                      {Products.map((product, index) => {
                        const productRatings = ratings.filter(
                          (rating) => rating.productId === product._id
                        );
                        const totalRatings = productRatings.length;
                        const totalRatingValue = productRatings.reduce(
                          (acc, curr) => acc + curr.rating,
                          0
                        );
                        const averageRating =
                          totalRatings > 0
                            ? totalRatingValue / totalRatings
                            : 0;
                        const myslug = CreateSlug(product.slug);

                        return (
                          layout.best_selling_laptop.includes(product._id) && (
                            <>
                              <SwiperSlide key={index}>
                                <div className="card card-product h-100">
                                  {/* Badges */}

                                  {/* Badges */}
                                  {/* Buttons */}
                                  <div className="product-buttons">
                                    <button
                                      className="btn-product btn-wishlist"
                                      type="button"
                                      data-bs-toggle="button"
                                      title="Add to wishlist"
                                    >
                                      <i className="ri-heart-line" />
                                    </button>
                                    <a
                                      className="btn-product btn-compare"
                                      href="#"
                                      title="Compare product"
                                    >
                                      <i className="ri-repeat-line" />
                                    </a>
                                    <a
                                      className="btn-product btn-view"
                                      href="#modal-quick-view"
                                      data-bs-toggle="modal"
                                      title="Quick preview"
                                    >
                                      <i className="ri-eye-line" />
                                    </a>
                                  </div>
                                  {/* Buttons */}
                                  {/* Preview Image */}
                                  <Link
                                    className="card-img-top d-block overflow-hidden flex-shrink-0"
                                    to={`/product/${myslug}/${product._id}`}
                                  >
                                    <img
                                      className="img-fluid"
                                      src={product.pImage}
                                      alt={` ${product.title} Product Image`}
                                    />
                                  </Link>
                                  {/* Preview Image */}
                                  <div className="card-body d-flex flex-column align-items-start flex-grow-1 rounded-bottom h-100 py-3">
                                    {/* Product Category */}

                                    {/* Product Category */}
                                    {/* Product Title */}
                                    <h3 className="product-title flex-grow-1">
                                      <Link
                                        to={`/product/${myslug}/${product._id}`}
                                      >
                                        {" "}
                                        {product.title}{" "}
                                      </Link>
                                    </h3>
                                    {/* Product Title */}
                                    {/* Star Rating */}
                                    <span
                                      className={`star-rating star-${
                                        Math.round(averageRating) * 2
                                      }`}
                                    />

                                    {/* Star Rating */}
                                    {/* Product Price */}
                                    <div className="product-price">
                                      <span className="text-danger fs-5">
                                        <b className="fw-medium">
                                          {" "}
                                          ₹{product.salePrice}{" "}
                                        </b>
                                        <del className="text-body-secondary ms-1">
                                          <small>
                                            ₹{product.regularPrice}{" "}
                                          </small>
                                        </del>
                                      </span>
                                    </div>
                                    {/* Product Price */}
                                    {/* Product Meta */}

                                    <div className="d-flex gap-2 mb-2">
                                      {product.variations.map((variation) => {
                                        if (variation.name === "Color") {
                                          return variation.value.map(
                                            (value, idx) => {
                                              const firstVisibleProduct =
                                                product.variant_products.find(
                                                  (product) =>
                                                    product.Color === value &&
                                                    product.images.length !== 0
                                                );
                                              const firstImage =
                                                firstVisibleProduct?.images[0]
                                                  .src;
                                              const visibleVariant =
                                                product.variant_products.find(
                                                  (variant) => variant.visible
                                                );

                                              return (
                                                <button
                                                  key={idx}
                                                  className="border-none p-0 rounded-circle"
                                                >
                                                  <img
                                                    src={firstImage}
                                                    className="rounded-circle"
                                                    title={value}
                                                    style={{
                                                      objectFit:
                                                        "cover !important",
                                                      aspectRatio: "1/1",
                                                      width: 35,
                                                    }}
                                                    onClick={(event) => {
                                                      const productImage =
                                                        event.target
                                                          .closest(
                                                            ".card-product"
                                                          )
                                                          .querySelector(
                                                            ".card-img-top img"
                                                          );
                                                      const visibleVariantSale =
                                                        event.target
                                                          .closest(
                                                            ".card-product"
                                                          )
                                                          .querySelector(
                                                            ".product-price span b"
                                                          );
                                                      const visibleVariantRegular =
                                                        event.target
                                                          .closest(
                                                            ".card-product"
                                                          )
                                                          .querySelector(
                                                            ".product-price span del"
                                                          );

                                                      if (visibleVariantSale) {
                                                        visibleVariantSale.innerText = `₹${firstVisibleProduct.sale_price}`;
                                                      }
                                                      if (
                                                        visibleVariantRegular
                                                      ) {
                                                        visibleVariantRegular.innerText = `₹${firstVisibleProduct.regular_price}`;
                                                      }
                                                      if (productImage) {
                                                        productImage.src =
                                                          firstImage;
                                                      }
                                                    }}
                                                    onLoad={(event) => {
                                                      const visibleVariantSale =
                                                        event.target
                                                          .closest(
                                                            ".card-product"
                                                          )
                                                          .querySelector(
                                                            ".product-price span b"
                                                          );
                                                      const visibleVariantRegular =
                                                        event.target
                                                          .closest(
                                                            ".card-product"
                                                          )
                                                          .querySelector(
                                                            ".product-price span del"
                                                          );

                                                      if (visibleVariantSale) {
                                                        visibleVariantSale.innerText = `₹${visibleVariant.sale_price}`;
                                                      }
                                                      if (
                                                        visibleVariantRegular
                                                      ) {
                                                        visibleVariantRegular.innerText = `₹${visibleVariant.regular_price}`;
                                                      }
                                                    }}
                                                  />{" "}
                                                </button>
                                              );
                                            }
                                          );
                                        } else {
                                          return null; // If the name is not "Color", don't render anything
                                        }
                                      })}
                                    </div>

                                    <span className="product-meta text-body-secondary fs-xs">
                                      {" "}
                                      {product.stock === 0 ? (
                                        <span className="text-danger">
                                          Out of stock
                                        </span>
                                      ) : product.stock <= 10 ? (
                                        <span>
                                          Only {product.stock} left in stock
                                        </span>
                                      ) : (
                                        <></>
                                      )}
                                    </span>
                                    {/* Product Meta */}
                                  </div>
                                  {/* Product Addon */}

                                  {/* Product Addon */}
                                </div>
                                {/* Product Cards */}
                              </SwiperSlide>
                            </>
                          )
                        );
                      })}
                    </>
                  )}
            </Swiper>
          </div>

          {/* Product Cards */}
        </div>
        {/* Product Cards */}

        {/* Banner */}
        <div className="container py-4 py-lg-10 d-none">
          <div className="row">
            {isLayoutLoading ? (
              <>
                <div
                  className="col skeleton "
                  style={{ height: 380, borderRadius: 10 }}
                ></div>
              </>
            ) : (
              layout.latest_product_banner && (
                <>
                  <div className="col">
                    <div
                      className="row justify-content-between align-items-center overflow-hidden border rounded-1"
                      style={{ backgroundColor: "white" }}
                    >
                      <div className="py-4 my-2 my-md-0 py-md-5 px-4 ms-md-3 text-center text-sm-start col-md-7">
                        <h6 className="fs-xs text-accent text-uppercase mb-2">
                          Weekend Discount
                        </h6>
                        <h3 className="fw-medium mb-2">
                          {layout.collection_heading || ""}
                        </h3>
                        <p className="fs-base fw-light mb-4">
                          {layout.collection_paragraph || ""}
                        </p>
                        <Link
                          className="btn btn-primary btn-shadow"
                          to={layout.collection_url || ""}
                        >
                          Shop Now
                        </Link>
                      </div>
                      <img
                        className="col-md-4 my-4"
                        src={layout.collection_img || ""}
                        alt="Shop Converse"
                        width={"100%"}
                      />
                    </div>
                  </div>
                </>
              )
            )}
          </div>
        </div>
        {/* Banner */}

        {/* Product Cards */}
        <div className="container pt-0 pt-sm-5 d-none">
          {/* Heading */}
          <div className="d-flex flex-wrap justify-content-between align-items-center pt-1 border-bottom pb-4 mb-4">
            <h2 className="h3 mb-0 me-2">Latest products</h2>
            <div className="ms-n4">
              {/* <a
                className="btn btn-sm btn-link link-info link-hover-primary d-flex align-items-center bg-btn-new"
                href="#"
              >
                View All
                <i className="ri-arrow-right-line ms-1" />
              </a> */}
            </div>
          </div>
          {/* Heading */}
          {/* Product Cards */}

          <div className="row pt-2 mx-n2 hero-swiper hide-desk-arrow">
            {/* Product Card */}
            <Swiper
              breakpoints={{
                300: {
                  slidesPerView: 2,
                  spaceBetween: 10, // Set the gap between slides for window width <= 400px
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 20, // Set the gap between slides for window width <= 768px
                },
                992: {
                  slidesPerView: 3,
                  spaceBetween: 25, // Set the gap between slides for window width <= 992px
                },
                1200: {
                  slidesPerView: 5,
                  spaceBetween: 25, // Set the gap between slides for window width <= 1200px
                },
              }}
              pagination={true}
              navigation={true}
              modules={[Pagination, Navigation]}
              className="swiper-wrapper"
            >
              {isProducts
                ? Array.from({ length: 7 }).map((_, index) => (
                    <SwiperSlide key={index}>
                      <div
                        className="card-1 skeleton"
                        style={{ height: 371, borderRadius: 10 }}
                      ></div>
                    </SwiperSlide>
                  ))
                : layout.latest_product && (
                    <>
                      {Products.map((product, index) => {
                        const productRatings = ratings.filter(
                          (rating) => rating.productId === product._id
                        );
                        const totalRatings = productRatings.length;
                        const totalRatingValue = productRatings.reduce(
                          (acc, curr) => acc + curr.rating,
                          0
                        );
                        const averageRating =
                          totalRatings > 0
                            ? totalRatingValue / totalRatings
                            : 0;
                        const myslug = CreateSlug(product.slug);

                        return (
                          layout.latest_product.includes(product._id) && (
                            <>
                              <SwiperSlide key={index}>
                                <div className="card card-product h-100">
                                  {/* Badges */}

                                  {/* Badges */}
                                  {/* Buttons */}
                                  <div className="product-buttons">
                                    <button
                                      className="btn-product btn-wishlist"
                                      type="button"
                                      data-bs-toggle="button"
                                      title="Add to wishlist"
                                    >
                                      <i className="ri-heart-line" />
                                    </button>
                                    <a
                                      className="btn-product btn-compare"
                                      href="#"
                                      title="Compare product"
                                    >
                                      <i className="ri-repeat-line" />
                                    </a>
                                    <a
                                      className="btn-product btn-view"
                                      href="#modal-quick-view"
                                      data-bs-toggle="modal"
                                      title="Quick preview"
                                    >
                                      <i className="ri-eye-line" />
                                    </a>
                                  </div>
                                  {/* Buttons */}
                                  {/* Preview Image */}
                                  <Link
                                    className="card-img-top d-block overflow-hidden flex-shrink-0"
                                    to={`/product/${myslug}/${product._id}`}
                                  >
                                    <img
                                      className="img-fluid"
                                      src={product.pImage}
                                      alt={` ${product.title} Product Image`}
                                    />
                                  </Link>
                                  {/* Preview Image */}
                                  <div className="card-body d-flex flex-column align-items-start flex-grow-1 rounded-bottom h-100 py-3">
                                    {/* Product Category */}

                                    {/* Product Category */}
                                    {/* Product Title */}
                                    <h3 className="product-title flex-grow-1">
                                      <Link
                                        to={`/product/${myslug}/${product._id}`}
                                      >
                                        {" "}
                                        {product.title}{" "}
                                      </Link>
                                    </h3>
                                    {/* Product Title */}
                                    {/* Star Rating */}
                                    <span
                                      className={`star-rating star-${
                                        Math.round(averageRating) * 2
                                      }`}
                                    />

                                    {/* Star Rating */}
                                    {/* Product Price */}
                                    <div className="product-price">
                                      <span className="text-danger fs-5">
                                        <b className="fw-medium">
                                          {" "}
                                          ₹{product.salePrice}{" "}
                                        </b>
                                        <del className="text-body-secondary ms-1">
                                          <small>
                                            ₹{product.regularPrice}{" "}
                                          </small>
                                        </del>
                                      </span>
                                    </div>
                                    {/* Product Price */}
                                    {/* Product Meta */}

                                    <div className="d-flex gap-2 mb-2">
                                      {product.variations.map((variation) => {
                                        if (variation.name === "Color") {
                                          return variation.value.map(
                                            (value, idx) => {
                                              const firstVisibleProduct =
                                                product.variant_products.find(
                                                  (product) =>
                                                    product.Color === value &&
                                                    product.images.length !== 0
                                                );
                                              const firstImage =
                                                firstVisibleProduct?.images[0]
                                                  .src;
                                              const visibleVariant =
                                                product.variant_products.find(
                                                  (variant) => variant.visible
                                                );

                                              return (
                                                <button
                                                  key={idx}
                                                  className="border-none p-0 rounded-circle"
                                                >
                                                  <img
                                                    src={firstImage}
                                                    className="rounded-circle"
                                                    title={value}
                                                    style={{
                                                      objectFit:
                                                        "cover !important",
                                                      aspectRatio: "1/1",
                                                      width: 35,
                                                    }}
                                                    onClick={(event) => {
                                                      const productImage =
                                                        event.target
                                                          .closest(
                                                            ".card-product"
                                                          )
                                                          .querySelector(
                                                            ".card-img-top img"
                                                          );
                                                      const visibleVariantSale =
                                                        event.target
                                                          .closest(
                                                            ".card-product"
                                                          )
                                                          .querySelector(
                                                            ".product-price span b"
                                                          );
                                                      const visibleVariantRegular =
                                                        event.target
                                                          .closest(
                                                            ".card-product"
                                                          )
                                                          .querySelector(
                                                            ".product-price span del"
                                                          );

                                                      if (visibleVariantSale) {
                                                        visibleVariantSale.innerText = `₹${firstVisibleProduct.sale_price}`;
                                                      }
                                                      if (
                                                        visibleVariantRegular
                                                      ) {
                                                        visibleVariantRegular.innerText = `₹${firstVisibleProduct.regular_price}`;
                                                      }
                                                      if (productImage) {
                                                        productImage.src =
                                                          firstImage;
                                                      }
                                                    }}
                                                    onLoad={(event) => {
                                                      const visibleVariantSale =
                                                        event.target
                                                          .closest(
                                                            ".card-product"
                                                          )
                                                          .querySelector(
                                                            ".product-price span b"
                                                          );
                                                      const visibleVariantRegular =
                                                        event.target
                                                          .closest(
                                                            ".card-product"
                                                          )
                                                          .querySelector(
                                                            ".product-price span del"
                                                          );

                                                      if (visibleVariantSale) {
                                                        visibleVariantSale.innerText = `₹${visibleVariant.sale_price}`;
                                                      }
                                                      if (
                                                        visibleVariantRegular
                                                      ) {
                                                        visibleVariantRegular.innerText = `₹${visibleVariant.regular_price}`;
                                                      }
                                                    }}
                                                  />{" "}
                                                </button>
                                              );
                                            }
                                          );
                                        } else {
                                          return null; // If the name is not "Color", don't render anything
                                        }
                                      })}
                                    </div>

                                    <span className="product-meta text-body-secondary fs-xs">
                                      {" "}
                                      {product.stock === 0 ? (
                                        <span className="text-danger">
                                          Out of stock
                                        </span>
                                      ) : product.stock <= 10 ? (
                                        <span>
                                          Only {product.stock} left in stock
                                        </span>
                                      ) : (
                                        <></>
                                      )}
                                    </span>
                                    {/* Product Meta */}
                                  </div>
                                  {/* Product Addon */}

                                  {/* Product Addon */}
                                </div>
                                {/* Product Cards */}
                              </SwiperSlide>
                            </>
                          )
                        );
                      })}
                    </>
                  )}
            </Swiper>
          </div>

          {/* Product Cards */}
        </div>
        {/* Product Cards */}

        <div className="container pt-0 pt-sm-5 d-none">
          <div className="row g-3 py-0">
            {isLayoutLoading ? (
              <>
                <div
                  className="col-md-6 skeleton "
                  style={{ height: 380, borderRadius: 10 }}
                ></div>{" "}
                <div
                  className="col-md-6 skeleton "
                  style={{ height: 380, borderRadius: 10 }}
                ></div>
              </>
            ) : (
              layout.latest_product_banner && (
                <>
                  {layout.latest_product_banner.map((banner, index) => (
                    <Link class="col-6" key={index} to={banner.imageUrlInput}>
                      <img src={banner.imageInput} className="w-100 rounded" />
                    </Link>
                  ))}
                </>
              )
            )}
          </div>

          <div className="row mt-4 pt-2 mx-n2 hero-swiper ">
            <Swiper
              breakpoints={{
                300: {
                  slidesPerView: 2,
                  spaceBetween: 10, // Set the gap between slides for window width <= 400px
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 20, // Set the gap between slides for window width <= 768px
                },
                992: {
                  slidesPerView: 3,
                  spaceBetween: 25, // Set the gap between slides for window width <= 992px
                },
                1200: {
                  slidesPerView: 4,
                  spaceBetween: 25, // Set the gap between slides for window width <= 1200px
                },
              }}
              navigation={false}
              pagination={true}
              modules={[Pagination, Navigation]}
              className="swiper-wrapper"
            >
              {isLayoutLoading
                ? Array.from({ length: 7 }).map((_, index) => (
                    <SwiperSlide key={index}>
                      <div
                        className="card-1 skeleton"
                        style={{ height: 355, borderRadius: 10 }}
                      ></div>
                    </SwiperSlide>
                  ))
                : layout.latest_product_carousal && (
                    <>
                      {layout.latest_product_carousal.map((carousal, index) => (
                        <SwiperSlide key={index}>
                          <Link to={carousal.imageUrlInput}>
                            <img
                              src={carousal.imageInput}
                              className="w-100 rounded"
                            />
                          </Link>
                        </SwiperSlide>
                      ))}
                    </>
                  )}
            </Swiper>
          </div>
        </div>

        {/* Product Cards */}
        <div className="container pt-0 pt-sm-5 d-none">
          {/* Heading */}
          <div className="d-flex flex-wrap justify-content-between align-items-center pt-1 border-bottom pb-4 mb-4">
            <h2 className="h3 mb-0 me-2">New Product Arrivals</h2>
            {/* <div className="ms-n4">
              <a
                className="btn btn-sm btn-link link-info link-hover-primary d-flex align-items-center bg-btn-new"
                href="#"
              >
                View All
                <i className="ri-arrow-right-line ms-1" />
              </a>
            </div> */}
          </div>
          {/* Heading */}
          {/* Product Cards */}

          <div className="row pt-2 mx-n2 hero-swiper hide-desk-arrow">
            {/* Product Card */}
            <Swiper
              breakpoints={{
                300: {
                  slidesPerView: 2,
                  spaceBetween: 10, // Set the gap between slides for window width <= 400px
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 20, // Set the gap between slides for window width <= 768px
                },
                992: {
                  slidesPerView: 3,
                  spaceBetween: 25, // Set the gap between slides for window width <= 992px
                },
                1200: {
                  slidesPerView: 5,
                  spaceBetween: 25, // Set the gap between slides for window width <= 1200px
                },
              }}
              pagination={true}
              modules={[Pagination, Navigation]}
              className="swiper-wrapper"
            >
              {isProducts
                ? Array.from({ length: 7 }).map((_, index) => (
                    <SwiperSlide key={index}>
                      <div
                        className="card-1 skeleton"
                        style={{ height: 371, borderRadius: 10 }}
                      ></div>
                    </SwiperSlide>
                  ))
                : layout.best_selling_smartphone && (
                    <>
                      {Products.map((product, index) => {
                        const productRatings = ratings.filter(
                          (rating) => rating.productId === product._id
                        );
                        const totalRatings = productRatings.length;
                        const totalRatingValue = productRatings.reduce(
                          (acc, curr) => acc + curr.rating,
                          0
                        );
                        const averageRating =
                          totalRatings > 0
                            ? totalRatingValue / totalRatings
                            : 0;

                        const myslug = CreateSlug(product.slug);

                        return (
                          layout.best_selling_smartphone.includes(
                            product._id
                          ) && (
                            <>
                              <SwiperSlide key={index}>
                                <div className="card card-product h-100">
                                  {/* Badges */}

                                  {/* Badges */}
                                  {/* Buttons */}
                                  <div className="product-buttons">
                                    <button
                                      className="btn-product btn-wishlist"
                                      type="button"
                                      data-bs-toggle="button"
                                      title="Add to wishlist"
                                    >
                                      <i className="ri-heart-line" />
                                    </button>
                                    <a
                                      className="btn-product btn-compare"
                                      href="#"
                                      title="Compare product"
                                    >
                                      <i className="ri-repeat-line" />
                                    </a>
                                    <a
                                      className="btn-product btn-view"
                                      href="#modal-quick-view"
                                      data-bs-toggle="modal"
                                      title="Quick preview"
                                    >
                                      <i className="ri-eye-line" />
                                    </a>
                                  </div>
                                  {/* Buttons */}
                                  {/* Preview Image */}
                                  <Link
                                    className="card-img-top d-block overflow-hidden flex-shrink-0"
                                    to={`/product/${myslug}/${product._id}`}
                                  >
                                    <img
                                      className="img-fluid"
                                      src={product.pImage}
                                      alt={` ${product.title} Product Image`}
                                    />
                                  </Link>
                                  {/* Preview Image */}
                                  <div className="card-body d-flex flex-column align-items-start flex-grow-1 rounded-bottom h-100 py-3">
                                    {/* Product Category */}

                                    {/* Product Category */}
                                    {/* Product Title */}
                                    <h3 className="product-title flex-grow-1">
                                      <Link
                                        to={`/product/${myslug}/${product._id}`}
                                      >
                                        {" "}
                                        {product.title}{" "}
                                      </Link>
                                    </h3>
                                    {/* Product Title */}
                                    {/* Star Rating */}
                                    <span
                                      className={`star-rating star-${
                                        Math.round(averageRating) * 2
                                      }`}
                                    />

                                    {/* Star Rating */}
                                    {/* Product Price */}
                                    <div className="product-price">
                                      <span className="text-danger fs-5">
                                        <b className="fw-medium">
                                          {" "}
                                          ₹{product.salePrice}{" "}
                                        </b>
                                        <del className="text-body-secondary ms-1">
                                          <small>
                                            ₹{product.regularPrice}{" "}
                                          </small>
                                        </del>
                                      </span>
                                    </div>
                                    {/* Product Price */}
                                    {/* Product Meta */}

                                    <div className="d-flex gap-2 mb-2">
                                      {product.variations.map((variation) => {
                                        if (variation.name === "Color") {
                                          return variation.value.map(
                                            (value, idx) => {
                                              const firstVisibleProduct =
                                                product.variant_products.find(
                                                  (product) =>
                                                    product.Color === value &&
                                                    product.images.length !== 0
                                                );
                                              const firstImage =
                                                firstVisibleProduct?.images[0]
                                                  .src;
                                              const visibleVariant =
                                                product.variant_products.find(
                                                  (variant) => variant.visible
                                                );

                                              return (
                                                <button
                                                  key={idx}
                                                  className="border-none p-0 rounded-circle"
                                                >
                                                  <img
                                                    src={firstImage}
                                                    className="rounded-circle"
                                                    title={value}
                                                    style={{
                                                      objectFit:
                                                        "cover !important",
                                                      aspectRatio: "1/1",
                                                      width: 35,
                                                    }}
                                                    onClick={(event) => {
                                                      const productImage =
                                                        event.target
                                                          .closest(
                                                            ".card-product"
                                                          )
                                                          .querySelector(
                                                            ".card-img-top img"
                                                          );
                                                      const visibleVariantSale =
                                                        event.target
                                                          .closest(
                                                            ".card-product"
                                                          )
                                                          .querySelector(
                                                            ".product-price span b"
                                                          );
                                                      const visibleVariantRegular =
                                                        event.target
                                                          .closest(
                                                            ".card-product"
                                                          )
                                                          .querySelector(
                                                            ".product-price span del"
                                                          );

                                                      if (visibleVariantSale) {
                                                        visibleVariantSale.innerText = `₹${firstVisibleProduct.sale_price}`;
                                                      }
                                                      if (
                                                        visibleVariantRegular
                                                      ) {
                                                        visibleVariantRegular.innerText = `₹${firstVisibleProduct.regular_price}`;
                                                      }
                                                      if (productImage) {
                                                        productImage.src =
                                                          firstImage;
                                                      }
                                                    }}
                                                    onLoad={(event) => {
                                                      const visibleVariantSale =
                                                        event.target
                                                          .closest(
                                                            ".card-product"
                                                          )
                                                          .querySelector(
                                                            ".product-price span b"
                                                          );
                                                      const visibleVariantRegular =
                                                        event.target
                                                          .closest(
                                                            ".card-product"
                                                          )
                                                          .querySelector(
                                                            ".product-price span del"
                                                          );

                                                      if (visibleVariantSale) {
                                                        visibleVariantSale.innerText = `₹${visibleVariant.sale_price}`;
                                                      }
                                                      if (
                                                        visibleVariantRegular
                                                      ) {
                                                        visibleVariantRegular.innerText = `₹${visibleVariant.regular_price}`;
                                                      }
                                                    }}
                                                  />{" "}
                                                </button>
                                              );
                                            }
                                          );
                                        } else {
                                          return null; // If the name is not "Color", don't render anything
                                        }
                                      })}
                                    </div>

                                    <span className="product-meta text-body-secondary fs-xs">
                                      {" "}
                                      {product.stock === 0 ? (
                                        <span className="text-danger">
                                          Out of stock
                                        </span>
                                      ) : product.stock <= 10 ? (
                                        <span>
                                          Only {product.stock} left in stock
                                        </span>
                                      ) : (
                                        <></>
                                      )}
                                    </span>
                                    {/* Product Meta */}
                                  </div>
                                  {/* Product Addon */}

                                  {/* Product Addon */}
                                </div>
                                {/* Product Cards */}
                              </SwiperSlide>
                            </>
                          )
                        );
                      })}
                    </>
                  )}
            </Swiper>
          </div>

          {/* Product Cards */}
        </div>
        {/* Product Cards */}

        {/* Brand Slider */}

        {/* <div className="barnd-logos py-4 py-lg-10">
    <div className="container">
      <div className="row">
        
        <div className="barnd-swiper">

        <Swiper     breakpoints={{
    300: {
      slidesPerView: 2,
      spaceBetween: 10,  
    },
    768: {
      slidesPerView: 2,
      spaceBetween: 20,  
    },
    992: {
      slidesPerView: 3,
      spaceBetween: 25,  
    },
    1200: {
      slidesPerView: 5,
      spaceBetween: 25,  
    },
  }} className="swiper-wrapper" >
           <SwiperSlide>
           <a className="d-block bg-white py-4 py-sm-3 px-2" href="#">
                    <img
                      className="d-block mx-auto"
                      src="/assets/front_img/shop/brands/01.png"
                      style={{ width: 165 }}
                      alt="Brand Logo"
                    />
                  </a>
           </SwiperSlide>

           <SwiperSlide>
           <a className="d-block bg-white py-4 py-sm-3 px-2" href="#">
                    <img
                      className="d-block mx-auto"
                      src="/assets/front_img/shop/brands/02.png"
                      style={{ width: 165 }}
                      alt="Brand Logo"
                    />
                  </a>
           </SwiperSlide>

           <SwiperSlide>
           <a className="d-block bg-white py-4 py-sm-3 px-2" href="#">
                    <img
                      className="d-block mx-auto"
                      src="/assets/front_img/shop/brands/03.png"
                      style={{ width: 165 }}
                      alt="Brand Logo"
                    />
                  </a>
           </SwiperSlide>

           <SwiperSlide>
           <a className="d-block bg-white py-4 py-sm-3 px-2" href="#">
                    <img
                      className="d-block mx-auto"
                      src="/assets/front_img/shop/brands/04.png"
                      style={{ width: 165 }}
                      alt="Brand Logo"
                    />
                  </a>
           </SwiperSlide>


           <SwiperSlide>
           <a className="d-block bg-white py-4 py-sm-3 px-2" href="#">
                    <img
                      className="d-block mx-auto"
                      src="/assets/front_img/shop/brands/05.png"
                      style={{ width: 165 }}
                      alt="Brand Logo"
                    />
                  </a>
           </SwiperSlide>

           <SwiperSlide>
           <a className="d-block bg-white py-4 py-sm-3 px-2" href="#">
                    <img
                      className="d-block mx-auto"
                      src="/assets/front_img/shop/brands/06.png"
                      style={{ width: 165 }}
                      alt="Brand Logo"
                    />
                  </a>
           </SwiperSlide>

           <SwiperSlide>
           <a className="d-block bg-white py-4 py-sm-3 px-2" href="#">
                    <img
                      className="d-block mx-auto"
                      src="/assets/front_img/shop/brands/07.png"
                      style={{ width: 165 }}
                      alt="Brand Logo"
                    />
                  </a>
           </SwiperSlide>


           <SwiperSlide>
           <a className="d-block bg-white py-4 py-sm-3 px-2" href="#">
                    <img
                      className="d-block mx-auto"
                      src="/assets/front_img/shop/brands/08.png"
                      style={{ width: 165 }}
                      alt="Brand Logo"
                    />
                  </a>
           </SwiperSlide>



           </Swiper >



        </div>

      </div>
    </div>
  </div> */}

        {/* Brand Slider */}

        {/* Product Widgets */}

        {/* Product Widgets */}

        <section className="section posts-entry posts-entry-sm bg-white">
          <div className="container">
            <h3 class="pb-4 text-center">Latest From The blog</h3>
            <div className="row">
              {!isLoading
                ? // Display loading skeletons while data is being fetched
                  Array.from({ length: 4 }).map((_, index) => (
                    <div className="col-md-6 col-lg-3" key={index}>
                      <div className="blog-entry mb-4">
                        <div
                          className="skeleton mb-3"
                          style={{ height: 200, borderRadius: 10 }}
                        ></div>

                        <p
                          className="skeleton"
                          style={{ width: 130, borderRadius: 5 }}
                        >
                          {" "}
                        </p>
                        <p
                          className="skeleton"
                          style={{ width: 150, borderRadius: 5 }}
                        >
                          {" "}
                        </p>
                        <p
                          className="skeleton"
                          style={{ width: 170, borderRadius: 5 }}
                        >
                          {" "}
                        </p>
                        <p
                          className="skeleton"
                          style={{ width: 110, borderRadius: 5 }}
                        >
                          {" "}
                        </p>
                      </div>
                    </div>
                  ))
                : Rblogs.map((blog) => (
                    <div className="col-md-6 col-lg-3 pb-4" key={blog._id}>
                      <div className="card overflow-hidden">
                        <Link
                          to={`/blog/${blog.slug}`}
                          className="img-link rounded position-relative d-flex align-items-center justify-content-center text-dark "
                          style={{ aspectRatio: "1/0.7", objectFit: "cover" }}
                        >
                          <div
                            class="spinner-border position-absolute z-index-1"
                            role="status"
                          ></div>
                          <img
                            src={blog.image}
                            className="img-fluid bg-white z-index-2 position-relative"
                            style={{ aspectRatio: "1/0.7", objectFit: "cover" }}
                          />
                        </Link>
                        <div class="p-4">
                          <h4>
                            <Link
                              to={`/blog/${blog.slug}`}
                              className="text-dark"
                            >
                              {blog.title}
                            </Link>
                          </h4>
                          <p>
                            <Link to={`/blog/${blog.slug}`} class="btn border">
                              Continue Reading
                            </Link>
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
            </div>
          </div>
        </section>
      </main>

      <Footer />
    </>
  );
};

export default Home;
