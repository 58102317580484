import { useState } from "react";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import axiosInstance, { adminurl } from "../../axiosInstance";
import Header from "../components/Header";
import Footer from "../components/Footer";

const SignupUserType = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true); // Add loading state

  const [SubmitLoading, setSubmitLoading] = useState(true); // Add loading state
  const [data, setData] = useState([]);
  const [filteredCities, setFilteredCities] = useState([]);

  const [inputs, setInputs] = useState({
    type: "1",
    username: "",
    phone: "",
    email: "",
    password: "",
    confirm_password: "",
    pincode: "",
    Gender: "1",
    DOB: "",
    address: "",
    // profile: null, // Changed to null to match file type
    // DLfile: null,
    // AadhaarFront: null,
    // AadhaarBack: null,
    // PoliceVerification: null,
    // PassPort: null,
    // Electricity: null,
    // WaterBill: null,
  });

  // //handle input change
  // const handleChange = (e) => {
  //     const { name, value, type, checked, files } = e.target;
  //     setInputs((prevData) => ({
  //         ...prevData,
  //         [name]:
  //             type === "checkbox" ? checked : type === "file" ? files[0] : value,
  //     }));
  // };

  const capitalizeEachWord = (str) => {
    if (!str) return str;
    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };

  const handleChange = (e) => {
    const { name, value, type, checked, files } = e.target;

    let newValue;
    if (type === "checkbox") {
      newValue = checked;
    } else if (type === "file") {
      newValue = files[0];
    } else {
      newValue = value;
    }

    // Capitalize text for the 'username' field
    if (name === "username") {
      newValue = capitalizeEachWord(newValue);
    }
    // Capitalize text for the 'username' field
    if (name === "state") {
      const selectedState = data.find((state) => state._id === newValue);
      if (selectedState) {
        setFilteredCities(selectedState.cities);
        setInputs((prevData) => ({
          ...prevData,
          statename: selectedState.name,
          city: "",
        }));
      }
      console.log("selectedState", selectedState, newValue, data);
    }

    setInputs((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));
  };

  const [showPassword, setShowPassword] = useState(false);

  // Function to toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async () => {
    setSubmitLoading(false);
    console.log(inputs);

    const fields = [
      { name: "username", message: "Please enter Full Name" },
      { name: "phone", message: "Please enter phone number" },
      { name: "email", message: "Please enter email" },
      { name: "password", message: "Please enter password" },
      { name: "confirm_password", message: "Please enter confirm password" },
      { name: "state", message: "Please enter State" },
      { name: "city", message: "Please enter State" },
      { name: "pincode", message: "Please enter pincode" },
      { name: "address", message: "Please enter address" },
      // Fields below are conditionally required based on inputs.type
      ...(inputs.type !== "3"
        ? [
            { name: "Gender", message: "Please enter Gender" },
            { name: "DOB", message: "Please enter DOB" },
          ]
        : []),
    ];

    for (const field of fields) {
      if (!inputs[field.name]) {
        toast.error(field.message);
        setSubmitLoading(true);
        return;
      }
    }

    if (inputs.password !== inputs.confirm_password) {
      toast.error("Password does not match");
      setSubmitLoading(true);
      return;
    }

    // Email Validation (basic regex)
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (!emailRegex.test(inputs.email)) {
      toast.error("Please enter a valid email address");
      setSubmitLoading(true);
      return;
    }

    // DOB Validation (age 18+)
    const dob = new Date(inputs.DOB);
    const today = new Date();
    const age = today.getFullYear() - dob.getFullYear();
    const m = today.getMonth() - dob.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < dob.getDate())) {
      age--;
    }

    if (age < 18) {
      toast.error("You must be at least 18 years old");
      setSubmitLoading(true);
      return;
    }

    console.log("mydata", inputs);

    try {
      const { data } = await axiosInstance.post(
        `/signup-user-type`,
        inputs
        //    {
        //   headers: {
        //     "Content-Type": "multipart/form-data",
        //   },
        // }
      );
      const { success } = data;
      if (success) {
        toast.success("Account Created Successfully  ");
        setTimeout(() => {
          window.location.href = adminurl;
        }, 1000);
      }
    } catch (error) {
      console.error("Error On Signup:", error);
      toast.error(error.response.data.message);
    } finally {
      setSubmitLoading(true);
    }

    setSubmitLoading(true);
  };

  const fetchData = async () => {
    setLoading(true);
    console.log("response.data start");

    try {
      const response = await axiosInstance.get("/get-all-zones");
      console.log("response.data.Zones,", response.data.Zones);
      setData(response.data.Zones);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const handleSidebarToggle = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <>
      <Header />

      <main className="page">
        <div className="container pt-0">
          <div
            className="dashboard-content bg-white px-4 py-3 "
            style={{ maxWidth: "100vw" }}
          >
            <div className="card  border-0">
              <form className="m-b20 row">
                <div className="col-md-6">
                  <label className="mb-2">Are you a Employee or vender </label>

                  <ul className="customradio input-group two-grid  mb-4 px-0 mx-0">
                    <li>
                      <input
                        type="radio"
                        name="type"
                        onChange={handleChange}
                        value="1"
                        checked={inputs.type === "1"}
                      />
                      <label>Vendor</label>
                    </li>
                    <li>
                      <input
                        type="radio"
                        name="type"
                        onChange={handleChange}
                        value="2"
                        checked={inputs.type === "2"}
                      />
                      <label>Doctor</label>
                    </li>
                    <li>
                      <input
                        type="radio"
                        name="type"
                        onChange={handleChange}
                        value="3"
                        checked={inputs.type === "3"}
                      />
                      <label>Hospital</label>
                    </li>
                  </ul>
                </div>
                <div className="mb-4">
                  <label className="form-label text-dark" htmlFor="name">
                    Full Name <span className="text-danger"> ( Required )</span>
                  </label>
                  <div className="input-group   input-sm">
                    <input
                      type="text"
                      id="username"
                      className="form-control"
                      name="username"
                      value={inputs.username}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="mb-4 col-6">
                  <label className="form-label text-dark" htmlFor="name">
                    Phone <span className="text-danger"> ( Required )</span>
                  </label>
                  <div className="input-group   input-sm">
                    <input
                      type="number"
                      id="phone"
                      className="form-control"
                      name="phone"
                      value={inputs.phone}
                      onChange={(e) => {
                        if (e.target.value.length <= 10) {
                          // Check input length before updating
                          handleChange(e); // Call handleChange if length is within limit
                        }
                      }}
                      onInput={(e) => {
                        const inputValue = e.target.value;
                        if (inputValue.length <= 10) {
                          setInputs({ ...inputs, phone: inputValue });
                        }
                      }}
                      // onChange={() => {
                      //   handleChange();
                      // }}
                    />
                  </div>
                </div>
                <div className="mb-4 col-6">
                  <label className="form-label text-dark" htmlFor="email">
                    Email <span className="text-danger"> ( Required )</span>
                  </label>
                  <div className="input-group   input-sm">
                    <input
                      type="email"
                      id="email"
                      className="form-control"
                      name="email"
                      value={inputs.email}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="mb-4 col-md-6">
                  <label className="form-label text-dark" htmlFor="password">
                    Password <span className="text-danger"> ( Required )</span>
                  </label>
                  <div className="input-group   input-sm">
                    <input
                      type={showPassword ? "text" : "password"}
                      id="password"
                      className="form-control dz-password"
                      name="password"
                      value={inputs.password}
                      onChange={handleChange} // Add onChange handler to manage input changes
                      // onKeyPress={(e) => {
                      //   if (e.key === 'Enter' && inputs.phone.length === 10) {
                      //     submitLoginPass();
                      //   }
                      // }}
                    />
                    <span className="input-group-text text-primary p-1">
                      {/* Toggle password visibility button */}
                      <i
                        className={
                          showPassword
                            ? "ri-eye-off-fill cursor"
                            : "ri-eye-fill cursor"
                        }
                        onClick={togglePasswordVisibility}
                      />
                    </span>
                  </div>
                </div>
                <div className="mb-4 col-md-6">
                  <label className="form-label text-dark" htmlFor="password">
                    Confirm Password{" "}
                    <span className="text-danger"> ( Required )</span>
                  </label>
                  <div className="input-group   input-sm">
                    <input
                      type={showPassword ? "text" : "password"}
                      id="password"
                      className="form-control dz-password"
                      name="confirm_password"
                      value={inputs.confirm_password}
                      onChange={handleChange} // Add onChange handler to manage input changes
                      // onKeyPress={(e) => {
                      //   if (e.key === 'Enter' && inputs.phone.length === 10) {
                      //     submitLoginPass();
                      //   }
                      // }}
                    />
                    <span className="input-group-text text-primary p-1">
                      {/* Toggle password visibility button */}
                      <i
                        className={
                          showPassword
                            ? "ri-eye-off-fill cursor"
                            : "ri-eye-fill cursor"
                        }
                        onClick={togglePasswordVisibility}
                      />
                    </span>
                  </div>
                </div>

                {inputs.type !== "3" && (
                  <div className="mb-4 col-6 ">
                    <label className="form-label text-dark" htmlFor="email">
                      Gender <span className="text-danger"> ( Required )</span>
                    </label>
                    <div className="input-group    input-sm">
                      <select
                        className="form-control"
                        value={inputs.gender}
                        name="gender"
                        onChange={handleChange}
                      >
                        <option selected disabled>
                          {" "}
                          Select Gender
                        </option>
                        <option value="1">Male</option>
                        <option value="2">Female</option>
                        <option value="0">Other</option>
                      </select>
                    </div>
                  </div>
                )}

                {inputs.type !== "3" && (
                  <div className="mb-4 col-6  ">
                    <label className="form-label text-dark" htmlFor="email">
                      DOB <span className="text-danger"> ( Required )</span>
                    </label>
                    <div className="input-group    input-sm">
                      <input
                        type="date"
                        id="username"
                        className="form-control custom-date"
                        name="DOB"
                        value={inputs.DOB}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                )}

                <div className="mb-4 col-6  ">
                  <label className="form-label text-dark" htmlFor="email">
                    State <span className="text-danger"> ( Required )</span>
                  </label>
                  <div className="input-group    input-sm">
                    <select
                      className="form-control"
                      id="state"
                      name="state"
                      value={inputs.state}
                      onChange={handleChange}
                    >
                      <option selected disabled>
                        {" "}
                        {loading ? "Loading..." : "Select State"}
                      </option>
                      {data.map((item) => (
                        <option key={item._id} value={item._id}>
                          {item.name}
                        </option>
                      ))}{" "}
                      ;
                    </select>
                  </div>
                </div>

                <div className="mb-4 col-6 ">
                  <label className="form-label text-dark" htmlFor="city">
                    City <span className="text-danger"> ( Required )</span>
                  </label>
                  <div className="input-group    input-sm">
                    <select
                      className="form-control"
                      id="city"
                      name="city"
                      value={inputs.city}
                      onChange={handleChange}
                    >
                      <option selected value={""}>
                        {" "}
                        {loading ? "Loading..." : "Select City"}
                      </option>
                      {filteredCities.map((city, index) => (
                        <option key={index} value={city}>
                          {city}
                        </option>
                      ))}{" "}
                      ;
                    </select>
                  </div>
                </div>

                <div className="mb-4 col-6  ">
                  <label className="form-label text-dark" htmlFor="email">
                    Pincode <span className="text-danger"> ( Required )</span>
                  </label>
                  <div className="input-group    input-sm">
                    <input
                      type="number"
                      id="pincode"
                      className="form-control custom-date"
                      name="pincode"
                      value={inputs.pincode}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="mb-4  ">
                  <label className="form-label text-dark" htmlFor="email">
                    Address <span className="text-danger"> ( Required )</span>
                  </label>
                  <div className="input-group    input-sm">
                    <textarea
                      className="form-control"
                      id="address"
                      name="address"
                      onChange={handleChange}
                      rows="2"
                    >
                      {inputs.address}
                    </textarea>
                  </div>
                </div>

                <div className="col-md-6 me-auto">
                  {SubmitLoading ? (
                    <button
                      className="btn btn-thin btn-md w-100 btn-dark rounded-xl"
                      type="button"
                      onClick={handleSubmit}
                    >
                      {inputs.type === "1" && "Signup as a vender"}{" "}
                      {inputs.type === "2" && "Signup as a Doctor"}
                      {inputs.type === "3" && "Signup as a Hospital"}
                    </button>
                  ) : (
                    <button
                      disabled
                      className="btn btn-thin btn-lg w-100 btn-dark rounded-xl"
                      type="button"
                    >
                      <span className="ms-1">Loading...</span>
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </>
  );
};

export default SignupUserType;
