import React, { useState, useEffect, useContext, Component } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "axios";
import { useDispatch } from "react-redux";
import { authActions } from "../../redux/store";
import { toast } from "react-hot-toast";
import Header from "../components/Header";
import Footer from "../components/Footer";

const About = () => {
  return (
    <>
      <Header />

      <main className="page">
        {/* Header */}
        {/* Header */}
        {/* Page Title */}
        <div className="py-4 mb-4 mb-lg-10 whitesmoke">
          <div className="container d-lg-flex justify-content-between align-items-center py-2 py-lg-4">
            <div className="pe-lg-4 text-center text-lg-start">
              <h1 className="h3 mb-0">About Us</h1>
            </div>
            <div className="pt-2 pt-lg-0">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb flex-lg-nowrap justify-content-center justify-content-lg-start">
                  <li className="breadcrumb-item">
                    <Link className="text-nowrap" to="/" previewlistener="true">
                      <i className="ri-store-2-line" /> Home{" "}
                    </Link>
                  </li>

                  <li
                    className="breadcrumb-item text-nowrap active"
                    aria-current="page"
                  >
                    About Us
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        {/* Page Title */}
        {/* Content */}
        <div className="container mb-4 mb-lg-10">
          <div className="row g-4 g-lg-10">
            <div className="col-md-12">
              <img
                src="/assets/img/About-hospital-traders.jpg"
                width="100%"
                className="rounded"
              />
              <p className="p-4">
                Hospital Traders a unit of Holistic 2 Healthy Pvt Ltd. We are a
                young dynamic team under supervision & supported by Doctors
                Fraternity to realize the Vision of the Organization.
              </p>
              <p
                className="border rounded  p-4 mb-4"
                style={{
                  background:
                    "linear-gradient(135deg,rgb(254,205,165) 0%,rgb(254,45,45) 50%,rgb(107,0,62) 100%)",
                }}
              >
                Our Vision – To be the best online platform For the Doctors. Of
                the Doctors and By the Doctors.
              </p>

              <p
                className="border rounded  p-4 mb-4"
                style={{
                  background:
                    "linear-gradient(135deg,rgb(254,205,165) 0%,rgb(254,45,45) 50%,rgb(107,0,62) 100%)",
                }}
              >
                Our Mission – To provide one-point solution of all hospital
                needs to achieve Doctor’s ambitions.
              </p>
              <p
                className="border rounded  p-4 mb-4"
                style={{
                  background:
                    "linear-gradient(135deg,rgb(254,205,165) 0%,rgb(254,45,45) 50%,rgb(107,0,62) 100%)",
                }}
              >
                Our Moto – Give more than you Take.
              </p>
              <h2 className="mb-3">What you have to do?</h2>
              <p className="mb-4">
                If you require NABH / NABL/Hospital planning and development
                /Hospital branding. fill the form online given below.
              </p>
              <h2 className="mb-3">Our Achievements</h2>
              <p className="mb-4">
                We have already completed 100+ Projects of NABH/NABL/Hospital
                Planning and Development/QCI Inspection and Hospital Branding
                and more than 30 Running projects in Delhi, Gurugram, Ghaziabad,
                Agra, Aligarh, Bulandshahr, Bareilly, Meerut, Panipat & Karnal.
                We have already done CME in association with Indian Medical
                Association Local Branch of Bareilly, Agra & Faridabad. We are a
                young and dynamic firm with core expertise in fulfilling any
                requirements for Hospital at ONE point by specialized team at
                affordable cost to fulfil the ambition of a Doctors.
              </p>

              <h2>
                <strong>We Deal in</strong>
              </h2>
              <ol>
                <li>
                  <Link
                    to="/nabh-accreditation-hospital"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Accreditation Consultancy (NABH &amp; NABL)
                  </Link>
                </li>
                <li>
                  <Link
                    to="/hospital-designing-and-planning"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Hospital Planning &amp; Development
                  </Link>
                </li>
                <li>Quality Department Outsourcing (QCI)</li>
                <li>Hospital Management Outsourcing</li>
                <li>Hospital Branding</li>
                <li>QCI Inspection</li>
                <li>Staff Training</li>
              </ol>
              <h2>
                <br />
              </h2>

              <figure className="wp-block-table">
                <table className="w-100">
                  <thead>
                    <tr>
                      <th>PART – A</th>
                      <th>PART – B</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <strong>
                          <a to="/rs-grover-memorial-hospital-delhi/">
                            R. S. Grover Hospital – Delhi
                          </a>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <a to="/valentis-cancer-hospital-cyberknife-centre-in-meerut/">
                            Valentis Cancer Hospital – Meerut
                          </a>
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>
                          <a to="/mahesh-hospital/">Mahesh Hospital – Delhi</a>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <a to="/global-hospital-bareilly/">
                            Global Hospital – Bareilly
                          </a>
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>
                          <a to="/dr-pd-gupta-mediscan-center-and-lab/">
                            P. D. Gupta Mediscan Centre – Delhi
                          </a>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <a to="/ajay-pratima-hospital/">
                            Ajay Pratima Hospital – Bareilly
                          </a>
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>
                          <a to="/goyal-hospital-urology-centre/">
                            Goyal Hospital &amp; Urology Centre – Delhi
                          </a>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <a to="/kailash-eye-hospital/">
                            Kailash Eye Hospital – Bareilly
                          </a>
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>
                          <a to="/ganesh-diagnostic-imaging-centre-pvt-ltd/">
                            Ganesh Diagnostic &amp; Imaging Centre – Delhi
                          </a>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <a to="/garg-hospital-path-labs-pvt-ltd/">
                            Garg Hospital &amp; Path Lab – Bareilly
                          </a>
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>
                          <a to="/j-p-memorial-hospital/">
                            J. P. Memorial Hospital – Delhi
                          </a>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <a to="/kailash-hospital/">
                            Kailash Hospital – Bareilly
                          </a>
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>
                          <a to="/arya-hospital/">Arya Hospital – Delhi</a>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <a to="/vaidik-maternity-and-plastic-surgery-center/">
                            Vaidik Hospital – Bareilly
                          </a>
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>
                          <a to="/patel-hospital/">Patel Hospital – Delhi</a>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <a to="/mahendra-gayatri-hospital/">
                            Mahendra Gayatri Hospital – Bareilly
                          </a>
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>
                          <a to="/galaxy-hospital/">Galaxy Hospital – Delhi</a>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <a to="/k-k-hospital/">K. K. Hospital Bareilly</a>
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>
                          <a to="/chirag-hospital/">
                            Chirag Hospital – Gurgaon
                          </a>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <a to="/sunayan-eye-hospital/">
                            Sunayan Eye Hospital – Bareilly
                          </a>
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>
                          <a to="/agra-heart-centre/">
                            Agra Heart Centre – Agra
                          </a>
                        </strong>
                      </td>
                      <td>Deepmala Hospital – Bareilly</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>
                          <a to="/g-r-hospital-research-centre/">
                            G. R. Hospital &amp; Research Centre – Agra
                          </a>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <a to="/dharamdutt-city-hospital/">
                            Dharamdutt City Hospital – Bareilly
                          </a>
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>
                          <a to="/seth-hospital-karnal/">
                            Seth Hospital – Karnal
                          </a>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <a to="/advanced-neo-paed-care-centre/">
                            Advance Neo Paeds Care Centre – Bareilly
                          </a>
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>
                          <a to="/ahuja-hospital/">Ahuja Hospital – Panipat</a>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <a to="/alaunus-hospital-critical-care-centre/">
                            Alaunus Hospital – Bareilly
                          </a>
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>
                          <a to="/aman-hospital/">Aman Hospital – Panipat</a>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <a to="/ruchi-atul-hospital-pvt-ltd-vaccination-center/">
                            Ruchi Atul Hospital – Bareilly
                          </a>
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <td>Apex Hospital &amp; Trauma Centre – Panipat</td>
                      <td />
                    </tr>
                  </tbody>
                </table>
              </figure>

              <h2>
                <strong>WHY SHOULD YOU CHOOSE US?</strong>
              </h2>
              <ol>
                <li>
                  We have completed approximately 100+ Hospitals NABH/NABL/QCI
                  Hospital Planning &amp; Development Completed &amp; More than
                  30 Running projects.
                </li>
                <li>Team of Doctors, Professionals &amp; Technical persons.</li>
                <li>
                  One-point solution of all your Hospital Management needs like
                  NABH/NABL Accreditation/QCI Inspection/Hospital Planning &amp;
                  Development &amp; Hospital Branding.
                </li>
                <li>
                  Get assistance in New Department Opening/Department
                  Outsourcing/Doctors Collaboration/Equipment’s Purchase
                  /Hospital Sale/ Purchase &amp; Service providers Details.
                </li>
                <li>
                  Faster NABH Accreditation will help to increase hospital
                  revenue Early.
                </li>
              </ol>
            </div>
          </div>
        </div>
        {/* Content */}
        {/* Cards */}

        {/* Cards */}
      </main>

      <Footer />
    </>
  );
};

export default About;
